import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { FolderZip, UploadFile } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import {
    acceptedTypesArray,
    acceptedTypesString,
} from '../../../helpers/Files';
import ErrorAlert from '../alerts/ErrorAlert';
import { FILE_OVER_500MB_FAILED } from '../../../helpers/Messages';

interface FileUploadProps {
    handleFileUpload: (value?: File) => void;
    file?: File;
    disabled?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
    handleFileUpload,
    file,
    disabled,
}) => {
    const { theme } = useCustomTheme();
    const [selectedFile, setSelectedFile] = useState<File | undefined>(file); // State for selected image

    const [error, setError] = useState<string>('');

    // Function to handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        const file = event.target.files?.[0]; // Only allow one image
        if (file) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            // File size validation: 500MB = 500 * 1024 * 1024 bytes
            const maxFileSize = 500 * 1024 * 1024; // 500MB in bytes
            if (file.size > maxFileSize) {
                setError(FILE_OVER_500MB_FAILED);
                event.target.value = ''; // Reset the file input
                return; // Stop further processing
            }

            if (!fileExtension) {
                return;
            }

            if (!acceptedTypesArray.includes(fileExtension)) {
                event.target.value = ''; // Reset the file input
                return;
            }

            // Clean up any previous object URL before creating a new one
            handleFileUpload(file);
            setSelectedFile(file); // Store file temporarily in state
        }

        // Reset the file input so the same image can be uploaded again
        event.target.value = '';
    };

    // Clear the uploaded image
    const clearFile = () => {
        setSelectedFile(undefined);
        handleFileUpload(undefined); // Call the upload handler with no image

        // Reset the file input so that the same image can be uploaded again
        const fileInput = document.getElementById(
            'fileUploadInput'
        ) as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // Reset the file input field
        }
    };

    // Trigger file input on card click
    const handleCardClick = () => {
        if (!selectedFile) {
            // Only trigger file input if no image is selected
            document.getElementById('fileUploadInput')?.click();
        }
    };

    return (
        <Box>
            {selectedFile ? ( // This checks if both are truthy
                <Card
                    sx={{
                        cursor: selectedFile ? 'default' : 'pointer',
                        transition: 'transform 0.2s',
                        backgroundColor: 'transparent',
                        border: `2px solid ${theme.palette.primary.main}`, // Use backticks for string interpolation
                        padding: '20px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                    onClick={handleCardClick}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: theme.palette.text.primary,
                            zIndex: 10,
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                            },
                        }}
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation();
                            clearFile();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <FolderZip
                        sx={{
                            fontSize: '164px',
                            color: theme.palette.primary.main,
                            marginBottom: '10px',
                            marginTop: '40px',
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={{
                            marginTop: '10px',
                            color: theme.palette.text.secondary,
                            marginBottom: '40px',
                        }}
                    >
                        {selectedFile.name}
                    </Typography>
                </Card>
            ) : (
                <Card
                    sx={{
                        cursor: selectedFile ? 'default' : 'pointer',
                        transition: 'transform 0.2s',
                        backgroundColor: 'transparent',
                        border: `2px solid ${theme.palette.primary.main}`, // Use backticks for string interpolation
                        padding: '20px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                    onClick={handleCardClick}
                >
                    {error ? <ErrorAlert message={error} /> : null}
                    <UploadFile
                        sx={{
                            fontSize: '164px',
                            color: theme.palette.primary.main,
                            marginBottom: '10px',
                            marginTop: '40px',
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={{
                            marginTop: '10px',
                            color: theme.palette.text.secondary,
                            marginBottom: '40px',
                        }}
                    >
                        Please select a file to upload (Max 500MB) <br />
                        <br />
                        Supported file types include:
                        <br />
                        ZIP
                        <br />
                        RAR
                        <br />
                        7ZIP
                    </Typography>
                </Card>
            )}
            <input
                id="fileUploadInput"
                type="file"
                accept={acceptedTypesString}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </Box>
    );
};

export default FileUpload;
