import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { isAsteriskPresent, mainLabel } from '../../../helpers/Labels';
import CaptionInput from './CaptionInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface TextInputProps {
    label: string;
    iconLabel?: JSX.Element;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string | JSX.Element;
    pageSearch?: boolean;
}

const LabelInput: React.FC<TextInputProps> = ({
    label,
    iconLabel,
    enableLabel,
    isMobile,
    caption,
    pageSearch,
}) => {
    const { theme } = useCustomTheme();

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={isMobile ? 12 : pageSearch ? 2.8 : 2.5}
            lg={enableLabel ? 12 : pageSearch ? 3 : 2.2}
            xl={enableLabel ? 12 : pageSearch ? 2.5 : 1.75}
            sx={{ textAlign: 'left' }} // Align content to the left
        >
            {/* Label and Asterisk */}
            <Typography
                variant="h6"
                sx={{
                    display: 'inline', // Remove the flex layout and use inline for text elements
                    color: theme.palette.text.primary,
                    marginBottom: '4px', // Add a small margin to prevent overlap with caption
                }}
            >
                <Box component="span">
                    {iconLabel
                        ? React.cloneElement(iconLabel, { size: 22 })
                        : mainLabel(label)}
                </Box>
                {isAsteriskPresent(label) && (
                    <Box
                        component="span"
                        sx={{
                            color: theme.palette.error.main,
                            marginLeft: '4px',
                        }}
                    >
                        *
                    </Box>
                )}
            </Typography>

            {/* Caption underneath the label */}
            {caption && <CaptionInput caption={caption} />}
        </Grid>
    );
};

export default LabelInput;
