import React, { useEffect } from 'react';
import { Box, Button, Typography, Link, Fade } from '@mui/material';
import logo from '../images/discovery-logo-2.png';
import { useApi } from '../contexts/ApiContext';
import { useNavigate } from 'react-router-dom';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';
import { useCustomTheme } from '../contexts/ThemeContext';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

// Define validation schema using Yup
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string()
        .min(10, 'Password should be of minimum 10 characters length')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Password must contain at least 1 uppercase character, 1 lowercase character, and 1 number'
        )
        .required('Password is required'),
});

const LoginPage: React.FC = () => {
    const { theme } = useCustomTheme();
    const navigate = useNavigate(); // Hook for navigation
    const { storeTokenAndUser } = useApi();

    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);

    useEffect(() => {}, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh', // Full height of the viewport
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    padding: 2, // Padding for mobile responsiveness
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px', // Limits the form's max width
                    }}
                >
                    {/* Logo */}
                    <Box
                        component="img"
                        src={logo} // Replace with your actual logo
                        alt="Logo"
                        sx={{ width: '200px', marginBottom: '2rem' }}
                    />

                    {/* Login Title */}
                    <Typography
                        variant="h2"
                        sx={{ fontWeight: 700, marginBottom: '0rem' }}
                    >
                        LOGIN
                    </Typography>

                    {/* Subtitle */}
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: '2rem',
                            color: theme.palette.text.disabled,
                        }}
                    >
                        please enter your email and password
                    </Typography>

                    {showError && <ErrorAlert message={error}></ErrorAlert>}

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            setShowError(false);
                            try {
                                const response = await apiWorker.login(
                                    values.email,
                                    values.password
                                );
                                storeTokenAndUser(response.accessToken);
                                navigate('/');
                            } catch (error) {
                                if (axios.isAxiosError(error)) {
                                    if (error.response?.data) {
                                        setError(error.response?.data.message);
                                    } else {
                                        setError(error.message);
                                    }
                                } else {
                                    setError(unknownError().message);
                                }
                                setShowError(true);
                            }
                        }}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleChange,
                            isSubmitting,
                            handleBlur,
                            isValid,
                            setFieldValue,
                        }) => (
                            <Form>
                                <TextInput
                                    id={'email'}
                                    label={'Email'}
                                    value={values.email}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.email}
                                    errors={errors.email}
                                    enableLabel={true}
                                />

                                <TextInput
                                    id={'password'}
                                    label={'Password'}
                                    value={values.password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.password}
                                    errors={errors.password}
                                    type="password"
                                    enableLabel={true}
                                />

                                {/* Forgot Password */}
                                <Box
                                    sx={{
                                        textAlign: 'right',
                                        alignSelf: 'flex-end',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <Link
                                        href="#"
                                        underline="hover"
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.text.disabled,
                                        }}
                                    >
                                        <i>forgot your password?</i>
                                    </Link>
                                </Box>

                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    sx={{
                                        padding: '0.8rem',
                                        marginBottom: 1.8,
                                    }}
                                >
                                    LOGIN
                                </Button>
                            </Form>
                        )}
                    </Formik>

                    {/* Register Link */}
                    <Typography variant="body2" sx={{ color: '#AAA' }}>
                        <Link
                            href="/register"
                            underline="hover"
                            variant="body2"
                            sx={{ color: theme.palette.text.disabled }}
                        >
                            <i>not a member yet? </i>{' '}
                            <i>
                                <b>Register!</b>
                            </i>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
};

export default LoginPage;
