// FileUploadPage.tsx
import React, { useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { Button, CardContent, Fade, Typography } from '@mui/material';
import { useCustomTheme } from '../contexts/ThemeContext';
import { useApi } from '../contexts/ApiContext';
import { FileUploaderProps, User } from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import Box from '@mui/material/Box';
import { Form, Formik } from 'formik';
import TextInput from '../components/universal/inputs/TextInput';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import ImageUpload from '../components/universal/images/ImageUploads';
import FileUpload from '../components/universal/files/FileUploads';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import ProgressAlert from '../components/universal/alerts/ProgressAlert';
import { v4 as uuidv4 } from 'uuid';
import {
    CREATE_DIRECT_UPLOAD_CORE_DATA_FAILED,
    GENERIC_ERROR,
    SEND_TO_DISCORD_FAILED,
    SEND_TO_DISCORD_SUCCESS,
    UPLOAD_FILE_FAILED,
    UPLOAD_IMAGE_FAILED,
} from '../helpers/Messages';

interface FileUploadPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

// Define validation schema using Yup
const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
});

const FileUploadPage: React.FC<FileUploadPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const { theme } = useCustomTheme();
    const { token } = useApi();
    const apiWorker = useWorker(createApiWorker);

    const [error, setError] = React.useState<string>('');
    const [success, setSuccess] = React.useState<string>('');
    const [progress, setProgress] = React.useState<string>();

    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined);
    const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

    const fileUploadDefault: FileUploaderProps = {
        description: '',
        title: '',
    };
    const [fileUpload, setFileUpload] =
        React.useState<FileUploaderProps>(fileUploadDefault);

    const handleImageUpload = async (value?: File) => {
        setUploadImage(value);
    };

    const handleFileUpload = async (value?: File) => {
        setUploadFile(value);
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <PageTopSection
                    isMobile={isMobile}
                    padding={padding}
                    hideSeperator={true}
                />
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                        marginBottom: isMobile ? 10 : 3,
                    }}
                >
                    <Card>
                        <CardContent>
                            <Fade in={true} timeout={fadeTimeout}>
                                <div>
                                    <Typography
                                        variant="h3"
                                        sx={{ marginTop: 1 }}
                                    >
                                        Upload File to Discord
                                    </Typography>
                                    <Formik
                                        initialValues={fileUpload}
                                        validationSchema={validationSchema}
                                        onSubmit={async (
                                            values,
                                            { resetForm }
                                        ) => {
                                            const fileUuid = uuidv4();
                                            setError('');
                                            setProgress('');
                                            setSuccess('');

                                            if (
                                                token &&
                                                uploadFile &&
                                                uploadImage
                                            ) {
                                                setProgress(
                                                    'Attempting to upload file'
                                                );

                                                let uploadedCoreData =
                                                    undefined;
                                                let uploadedFile = undefined;
                                                let uploadedImage = undefined;
                                                let sendtoDiscord = undefined;

                                                try {
                                                    uploadedCoreData =
                                                        await apiWorker.postDirectUpload(
                                                            {
                                                                name: values.title,
                                                                description:
                                                                    values.description,
                                                                uuid: fileUuid,
                                                            },
                                                            token
                                                        );
                                                } catch (error) {
                                                    setError(
                                                        CREATE_DIRECT_UPLOAD_CORE_DATA_FAILED
                                                    );
                                                    setProgress('');
                                                }

                                                if (uploadedCoreData) {
                                                    try {
                                                        uploadedFile =
                                                            await apiWorker.postDirectUploadFile(
                                                                {
                                                                    type: 'file',
                                                                    file: uploadFile,
                                                                },
                                                                fileUuid,
                                                                token
                                                            );
                                                    } catch (error) {
                                                        setError(
                                                            UPLOAD_FILE_FAILED
                                                        );
                                                        setProgress('');
                                                    }

                                                    if (uploadedFile) {
                                                        setProgress(
                                                            'File Successfully Uploaded.\nAttempting to upload image'
                                                        );

                                                        try {
                                                            uploadedImage =
                                                                await apiWorker.postDirectUploadFile(
                                                                    {
                                                                        type: 'image',
                                                                        file: uploadImage,
                                                                    },
                                                                    fileUuid,
                                                                    token
                                                                );
                                                        } catch (error) {
                                                            setError(
                                                                UPLOAD_IMAGE_FAILED
                                                            );
                                                            setProgress('');
                                                        }

                                                        if (uploadedImage) {
                                                            setProgress(
                                                                'File Successfully Uploaded.\nImage Successfully Uploaded.\nAttempting to send to discord'
                                                            );

                                                            try {
                                                                sendtoDiscord =
                                                                    await apiWorker.postDirectUploadDiscord(
                                                                        fileUuid,
                                                                        token
                                                                    );
                                                            } catch (error) {
                                                                setError(
                                                                    SEND_TO_DISCORD_FAILED
                                                                );
                                                                setProgress('');
                                                            }

                                                            if (sendtoDiscord) {
                                                                setError('');
                                                                setProgress('');
                                                                setSuccess(
                                                                    SEND_TO_DISCORD_SUCCESS
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                setError(GENERIC_ERROR);
                                            }
                                        }}
                                    >
                                        {({
                                            errors,
                                            touched,
                                            values,
                                            handleChange,
                                            isSubmitting,
                                            handleBlur,
                                            isValid,
                                            setFieldValue,
                                        }) => (
                                            <Form>
                                                <TextInput
                                                    id={'title'}
                                                    label={'TITLE *'}
                                                    value={values.title}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    touched={touched.title}
                                                    errors={errors.title}
                                                    isMobile={isMobile}
                                                />
                                                <TextInput
                                                    id={'description'}
                                                    label={'DESCRIPTION *'}
                                                    value={values.description}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    touched={
                                                        touched.description
                                                    }
                                                    errors={errors.description}
                                                    isMobile={isMobile}
                                                    multiline={true}
                                                    caption={
                                                        <>
                                                            Please use the
                                                            Discord Markdown.
                                                            <br />
                                                            Check out this{' '}
                                                            <a
                                                                href="https://support.discord.com/hc/en-us/articles/210298617-Markdown-Text-101-Chat-Formatting-Bold-Italic-Underline"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: theme
                                                                        .palette
                                                                        .warning
                                                                        .main,
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                            >
                                                                link
                                                            </a>{' '}
                                                            for more info!
                                                        </>
                                                    }
                                                />
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        marginTop: 4,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    Image
                                                </Typography>
                                                <ImageUpload
                                                    uploadData={{
                                                        title: 'test',
                                                        description: 'test',
                                                        type: undefined,
                                                        games: [
                                                            {
                                                                uuid: 'string',
                                                                name: 'string',
                                                                system: 'string',
                                                                image: {
                                                                    main: 'string',
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                    file={uploadImage}
                                                    handleImageUpload={
                                                        handleImageUpload
                                                    }
                                                    specificText={
                                                        'Please select an image to upload'
                                                    }
                                                    disabled={isSubmitting}
                                                />
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        marginTop: 4,
                                                        marginBottom: 1,
                                                    }}
                                                >
                                                    File
                                                </Typography>
                                                <FileUpload
                                                    file={uploadFile}
                                                    handleFileUpload={
                                                        handleFileUpload
                                                    }
                                                    disabled={isSubmitting}
                                                />
                                                <br />
                                                {error && (
                                                    <ErrorAlert
                                                        message={error}
                                                    ></ErrorAlert>
                                                )}

                                                {success && (
                                                    <SuccessAlert
                                                        message={success}
                                                    ></SuccessAlert>
                                                )}

                                                {progress && (
                                                    <ProgressAlert
                                                        message={progress}
                                                    ></ProgressAlert>
                                                )}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    <Button
                                                        type="submit"
                                                        disabled={
                                                            isSubmitting ||
                                                            !isValid ||
                                                            !uploadImage ||
                                                            !uploadFile
                                                        }
                                                        variant="contained"
                                                        sx={{
                                                            padding: '0.8rem',
                                                        }}
                                                    >
                                                        UPLOAD
                                                    </Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Fade>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Fade>
    );
};

export default FileUploadPage;
