import React, { useEffect, useState } from 'react';
import { useApi } from '../../contexts/ApiContext';
import Box from '@mui/material/Box';
import { FormControl, MenuItem, Select } from '@mui/material';
import UserLogin from './UserLogin';
import UserAvatar from './UserAvatar';
import { User, UserAccountTypes } from '../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom';

interface UserActionsProps {
    isMobile: boolean;
    user?: User;
}

const UserActions: React.FC<UserActionsProps> = ({ isMobile, user }) => {
    const { logout, token } = useApi();
    const navigate = useNavigate();

    const defaultDropdownMarginLeft = '0px';

    const [dropdownMarginLeft, setDropdownMarginLeft] = useState<string>(
        defaultDropdownMarginLeft
    );

    useEffect(() => {
        const platform = navigator?.platform || '';
        const userAgent = navigator?.userAgent || '';

        if (/Mac/.test(platform)) {
            setDropdownMarginLeft('-7.5px');
        } else if (/Win/.test(platform)) {
            setDropdownMarginLeft('-24px');
        } else if (/Linux/.test(platform)) {
            setDropdownMarginLeft(defaultDropdownMarginLeft);
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            setDropdownMarginLeft(defaultDropdownMarginLeft);
        } else if (/Android/.test(userAgent)) {
            setDropdownMarginLeft(defaultDropdownMarginLeft);
        } else {
            setDropdownMarginLeft(defaultDropdownMarginLeft);
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            {token && user ? (
                <FormControl size="small">
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value="1" // Keep this as a placeholder value so the renderValue works
                        renderValue={() => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <UserAvatar
                                    backup={user.userProfile.username}
                                    dimensions={24}
                                    marginRight={1}
                                    border={undefined}
                                    userProfile={user?.userProfile}
                                />
                                {user.userProfile.username}
                            </Box>
                        )}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    marginTop: isMobile ? '-33.5px' : '0px', // Adjust top margin for mobile
                                    marginLeft: isMobile
                                        ? '0px'
                                        : dropdownMarginLeft, // Adjust left margin for desktop
                                },
                            },
                        }}
                    >
                        {/* These are the actual selectable options */}
                        <MenuItem>View Notifications</MenuItem>
                        <MenuItem
                            onClick={() => {
                                const userProfileUuid = user.userProfile.uuid;
                                navigate(`/${user.userProfile.username}`, {
                                    state: { userProfileUuid },
                                });
                            }}
                        >
                            My Profile
                        </MenuItem>
                        {user.userProfile.canDirectUpload ? (
                            <MenuItem
                                onClick={() => {
                                    navigate(`/file-upload`);
                                }}
                            >
                                Discord Uploader
                            </MenuItem>
                        ) : null}
                        {user.userProfile.accountType !==
                        UserAccountTypes.MEMBER ? (
                            <MenuItem onClick={() => navigate('/admin')}>
                                Admin
                            </MenuItem>
                        ) : null}
                        <MenuItem onClick={() => logout()}>Logout</MenuItem>
                    </Select>
                </FormControl>
            ) : (
                <UserLogin />
            )}
        </Box>
    );
};

export default UserActions;
