// HomePage.tsx
import React from 'react';
import Box from '@mui/material/Box';
import { Fade, Grid, Pagination } from '@mui/material';
import {
    PreviewCardSizeOthers,
    SearchCardSizeOthers,
} from '../../helpers/UploadCardSizings';
import { Upload, User } from '../../workers/ApiWorker';
import { fadeTimeout } from '../../helpers/Themes';
import LoadingPreviewCard from '../universal/cards/LoadingPreviewCard';
import PreviewCard from '../universal/cards/PreviewCard';
import NoResultsFound from '../universal/loader/NoResultsFound';

interface SearchResultsProps {
    isMobile: boolean;
    uploads: Upload[];
    loadingResults: boolean;
    previewCardSizes:
        | typeof PreviewCardSizeOthers
        | typeof SearchCardSizeOthers;
    lastPage: number;
    currentPage: number;
    handlePageChange: (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => void;
    user?: User;
    count?: number;
    handleReuseDetails?: (upload: Upload) => void;
}

const SearchResults: React.FC<SearchResultsProps> = ({
    isMobile,
    loadingResults,
    uploads,
    previewCardSizes,
    lastPage,
    currentPage,
    handlePageChange,
    user,
    count = 12,
    handleReuseDetails,
}) => {
    return (
        <Box sx={{ marginTop: 2 }}>
            <>
                <Grid container spacing={2} marginBottom={isMobile ? 2 : 0}>
                    {loadingResults ? (
                        Array.from({
                            length: count,
                        }).map((_, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                sx={previewCardSizes}
                            >
                                <Fade in={true} timeout={fadeTimeout}>
                                    <div>
                                        {/* Show PreviewCard if uploads has data */}
                                        <LoadingPreviewCard invisible={false} />
                                    </div>
                                </Fade>
                            </Grid>
                        ))
                    ) : // If not loading
                    uploads.length > 0 ? (
                        Array.from({ length: count }).map((_, index) => {
                            if (index < uploads.length) {
                                const upload = uploads[index];
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        key={upload.uuid}
                                        sx={previewCardSizes}
                                    >
                                        <Fade
                                            in={true}
                                            timeout={fadeTimeout / 2}
                                        >
                                            <div>
                                                <PreviewCard
                                                    uuid={upload.uuid}
                                                    title={upload.name}
                                                    username={
                                                        upload.author.username
                                                    }
                                                    description={
                                                        upload.miniDescription
                                                    }
                                                    image={upload.images.main}
                                                    games={upload.games}
                                                    type={upload.type}
                                                    upload={upload}
                                                    user={user}
                                                    handleReuseDetails={
                                                        handleReuseDetails
                                                    }
                                                />
                                            </div>
                                        </Fade>
                                    </Grid>
                                );
                            } else {
                                // Fill remaining slots with LoadingPreviewCard
                                // return (
                                //     <Grid
                                //         item
                                //         xs={12}
                                //         key={`placeholder-${index}`}
                                //         sx={previewCardSizes}
                                //     >
                                //         <Fade in={true} timeout={fadeTimeout}>
                                //             <div>
                                //                 <LoadingPreviewCard
                                //                     invisible={true}
                                //                 />
                                //             </div>
                                //         </Fade>
                                //     </Grid>
                                // );
                            }
                        })
                    ) : (
                        <NoResultsFound />
                    )}
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: isMobile ? 10 : 3,
                        marginTop: 3,
                    }}
                >
                    {uploads.length > 0 ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <Pagination
                                showFirstButton
                                showLastButton
                                count={lastPage}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Fade>
                    ) : null}
                </Box>
            </>
        </Box>
    );
};

export default SearchResults;
