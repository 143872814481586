import React, { useEffect, useState } from 'react';
import { Divider, Typography, Button, Box } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { Report, updateReport, Upload } from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useApi } from '../../contexts/ApiContext';
import axios from 'axios';
import { unknownError } from '../../helpers/ApiResponses';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import SuccessAlert from '../universal/alerts/SuccessAlert';
import GradientBorder from '../universal/borders/GradientBorder';
import { convertSimple } from '../../helpers/Date';
import {
    REPORT_REMOVED_FAILED,
    REPORT_REMOVED_SUCCESS,
} from '../../helpers/Messages';

interface ViewReportsModalProps {
    isMobile: boolean;
    closeModal: (resetForm?: () => void) => void;
    setError: (error: string | undefined) => void;
    setSuccess: (success: string | undefined) => void;
    isModalOpen: boolean;
    error?: string;
    success?: string;
    uploadUuid: string;
    reports: Report[];
    activeReportId: null | string;
    setActiveReportId: (id: string | null) => void;
    setReports: (reports: Report[]) => void;
    setUpload: (upload: Upload) => void;
}

const validationSchema = Yup.object({
    reason: Yup.string().required('Reason is required'),
});

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const ViewReportsModal: React.FC<ViewReportsModalProps> = ({
    isMobile,
    closeModal,
    setError,
    setSuccess,
    isModalOpen,
    error,
    success,
    reports,
    setActiveReportId,
    activeReportId,
    setReports,
    uploadUuid,
    setUpload,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const { theme } = useCustomTheme();

    const handleReset = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setActiveReportId(null);
    };

    return (
        <Formik
            initialValues={{
                reason: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                setError(undefined);
                setSuccess(undefined);

                if (!token || !activeReportId) {
                    return;
                }

                try {
                    const result = await apiWorker.updateReport(
                        {
                            response: values.reason,
                        },
                        token,
                        uploadUuid,
                        activeReportId
                    );

                    setSuccess(REPORT_REMOVED_SUCCESS);
                    setReports(
                        reports.filter(
                            (report) => report.uuid !== activeReportId
                        )
                    );
                    setUpload(result.data);
                    handleReset(resetForm);
                } catch (error) {
                    setError(REPORT_REMOVED_FAILED);
                }
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                    cancelLabel={'close'}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: 3,
                        }}
                        color={theme.palette.text.secondary}
                    >
                        REPORTS
                    </Typography>
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    {success && <SuccessAlert message={success}></SuccessAlert>}
                    {reports.length > 0 &&
                        reports.map((report, key) => (
                            <div key={key}>
                                <Typography
                                    variant="caption"
                                    color={theme.palette.warning.main}
                                >
                                    {convertSimple(report.createdAt)}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color={theme.palette.text.secondary}
                                >
                                    {report.username}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    color={theme.palette.text.disabled}
                                >
                                    {report.reason}
                                </Typography>
                                {!activeReportId && (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                        onClick={() =>
                                            setActiveReportId(report.uuid)
                                        }
                                    >
                                        Remove Report
                                    </Button>
                                )}
                                {activeReportId === report.uuid && (
                                    <div>
                                        <TextInput
                                            id={'reason'}
                                            label={'Reason'}
                                            enableLabel={true}
                                            value={values.reason}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            hasSubmitted={isSubmitting}
                                            touched={touched.reason}
                                            errors={errors.reason}
                                            isMobile={isMobile}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                marginTop: 2,
                                                marginBottom: 2,
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSubmit()}
                                                disabled={
                                                    !isValid || isSubmitting
                                                }
                                            >
                                                SUBMIT
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                onClick={() =>
                                                    handleReset(resetForm)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </div>
                                )}
                                <Divider
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                />
                            </div>
                        ))}
                </CustomModal>
            )}
        </Formik>
    );
};

export default ViewReportsModal;
