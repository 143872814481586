// ViewUploadPage.tsx
import React, { useEffect, useState } from 'react';
import {
    ClickAwayListener,
    Container,
    Fade,
    Grid,
    Grow,
    MenuList,
    Popper,
} from '@mui/material';
import { Upload, User } from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useCustomTheme } from '../contexts/ThemeContext';
import CoreInformation from '../components/upload/CoreInformation';
import Breadcrumbs from '../components/upload/Breadcrumbs';
import DownloadAndHearts from '../components/upload/DownloadAndHearts';
import DetailedInformation from '../components/upload/DetailedInformation';
import DescriptionArea from '../components/upload/DescriptionArea';
import Tags from '../components/upload/Tags';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import NoImage from '../components/upload/NoImage';
import { convertYTLink } from '../helpers/YouTube';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';

interface ViewUploadPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const ViewUploadPage: React.FC<ViewUploadPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const { token } = useApi();

    const { theme } = useCustomTheme();

    const { uuid, username } = useParams<{ uuid: string; username: string }>(); // Extract uuid from URL

    const apiWorker = useWorker(createApiWorker);

    const [loadingUpload, setLoadingUpload] = useState<boolean>(true);

    const [upload, setUpload] = useState<Upload | null>(null);

    const [error, setError] = useState<string | undefined>(undefined);

    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);

    const [selectedVersionIndex, setSelectedVersionIndex] = useState<
        number | null
    >(null);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const options = ['latest'];

    const handleClick = () => {
        if (selectedVersionIndex !== null) {
            console.info(`You clicked ${options[selectedVersionIndex]}`);
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        setSelectedVersionIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        const getUpload = async () => {
            if (uuid && username) {
                setLoadingUpload(true);
                try {
                    const response = await apiWorker.getUpload({ uuid }, token);

                    if (response.data.author.username === username) {
                        setLoadingUpload(false);
                        setUpload(response.data);
                        setSelectedVersionIndex(
                            response.data.versions.length - 1
                        );
                    } else {
                        setNoResultsFound(true);
                    }
                } catch (error) {
                    setNoResultsFound(true);
                }
            }
        };
        getUpload()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching upload:', error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <div>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    {noResultsFound ? (
                        <NoResultsFound />
                    ) : (
                        !loadingUpload &&
                        upload && (
                            <>
                                {upload?.images?.main && (
                                    <Fade in={true} timeout={fadeTimeout}>
                                        <div>
                                            <Box
                                                sx={{
                                                    backgroundImage: `url(${upload.images.main})`,
                                                    backgroundSize: 'cover',
                                                    objectPosition: 'top',
                                                    position: 'fixed',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    width: '100vw', // Ensure full viewport width
                                                    height: '100vh', // Ensure full viewport height
                                                    filter: 'blur(13px)', // Apply blur effect
                                                    zIndex: 1, // Ensure it's below the content
                                                }}
                                            />

                                            {/* Semi-transparent overlay */}
                                            <Box
                                                sx={{
                                                    position: 'fixed',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    width: '100vw', // Ensure full viewport width
                                                    height: '100vh', // Ensure full viewport height
                                                    background:
                                                        theme.palette.secondary
                                                            .dark,
                                                    zIndex: 1,
                                                }}
                                            />
                                        </div>
                                    </Fade>
                                )}

                                {/* Main content - Container is required in order to get the background image working */}
                                <Container
                                    disableGutters
                                    maxWidth={false}
                                    sx={{ position: 'relative', zIndex: 2 }}
                                >
                                    <PageTopSection
                                        isMobile={isMobile}
                                        padding={padding}
                                        advancedContent={
                                            <>
                                                <Fade
                                                    in={true}
                                                    timeout={fadeTimeout}
                                                >
                                                    <div>
                                                        <Breadcrumbs
                                                            upload={upload}
                                                            isMobile={isMobile}
                                                        />
                                                    </div>
                                                </Fade>
                                            </>
                                        }
                                    />

                                    {/* Rest of the content remains unchanged */}
                                    <Box
                                        sx={{
                                            paddingLeft: getOverallPadding(
                                                isMobile,
                                                padding
                                            ),
                                            paddingRight: getOverallPadding(
                                                isMobile,
                                                padding
                                            ),
                                        }}
                                    >
                                        {selectedVersionIndex !== null ? (
                                            <Fade
                                                in={true}
                                                timeout={fadeTimeout}
                                            >
                                                <div>
                                                    <Stack
                                                        sx={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {error ? (
                                                            <ErrorAlert
                                                                message={error}
                                                            />
                                                        ) : null}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={10}
                                                            >
                                                                <CoreInformation
                                                                    upload={
                                                                        upload
                                                                    }
                                                                    isMobile={
                                                                        isMobile
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={2}
                                                            >
                                                                <DownloadAndHearts
                                                                    upload={
                                                                        upload
                                                                    }
                                                                    selectedVersion={
                                                                        upload
                                                                            .versions[
                                                                            selectedVersionIndex
                                                                        ]
                                                                    }
                                                                    user={user}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={
                                                                    isMobile
                                                                        ? 12
                                                                        : 6
                                                                }
                                                            >
                                                                {upload?.images
                                                                    ?.main ? (
                                                                    <Box
                                                                        sx={{
                                                                            marginTop: 2,
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                            paddingTop:
                                                                                '56.25%',
                                                                            display:
                                                                                'flex', // Use flexbox for centering
                                                                            justifyContent:
                                                                                'center', // Horizontally center the image
                                                                            alignItems:
                                                                                'center', // Vertically center the image
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                borderRadius:
                                                                                    '4px', // Rounded corners
                                                                                position:
                                                                                    'absolute',
                                                                                top: 0,

                                                                                maxHeight:
                                                                                    '100%', // Ensure the image height fills the container
                                                                                maxWidth:
                                                                                    '100%', // Prevent the image from overflowing horizontally
                                                                                height: '100%', // Stretch vertically to fill the container height
                                                                                width: 'auto', // Let the width adjust automatically based on the height
                                                                                objectFit:
                                                                                    'contain', // Ensure the image scales to fill height without horizontal stretch
                                                                            }}
                                                                            alt={
                                                                                upload.name
                                                                            }
                                                                            src={
                                                                                upload
                                                                                    .images
                                                                                    .main
                                                                            }
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <NoImage />
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={
                                                                    isMobile
                                                                        ? 12
                                                                        : 6
                                                                }
                                                            >
                                                                {upload.videoUrl ? (
                                                                    <div
                                                                        style={{
                                                                            marginTop: 15,
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                            overflow:
                                                                                'hidden',
                                                                            paddingTop:
                                                                                '56.25%', // 16:9 Aspect Ratio
                                                                        }}
                                                                    >
                                                                        <iframe
                                                                            style={{
                                                                                position:
                                                                                    'absolute',
                                                                                top: 0,
                                                                                left: 0,
                                                                                bottom: 0,
                                                                                right: 0,
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                border: 'none',
                                                                                borderRadius:
                                                                                    '4px', // Rounded corners
                                                                            }}
                                                                            src={convertYTLink(
                                                                                upload.videoUrl
                                                                            )}
                                                                            title="YouTube video"
                                                                        ></iframe>
                                                                    </div>
                                                                ) : null}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <DetailedInformation
                                                                upload={upload}
                                                                setUpload={
                                                                    setUpload
                                                                }
                                                                isMobile={
                                                                    isMobile
                                                                }
                                                                version={
                                                                    upload
                                                                        .versions[
                                                                        selectedVersionIndex
                                                                    ]
                                                                }
                                                                user={user}
                                                            />
                                                            <DescriptionArea
                                                                upload={upload}
                                                                image={
                                                                    upload
                                                                        .images
                                                                        .main
                                                                }
                                                            />
                                                            <Tags
                                                                upload={upload}
                                                            />
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                sx={{
                                                                    marginTop: 2.5,
                                                                    marginBottom:
                                                                        isMobile
                                                                            ? 12
                                                                            : 6.5,
                                                                }}
                                                            >
                                                                {/*
                                                                <ButtonGroup
                                                                    variant="contained"
                                                                    ref={
                                                                        anchorRef
                                                                    }
                                                                    aria-label="Button group with a nested menu"
                                                                >
                                                                    <Button
                                                                        onClick={
                                                                            handleClick
                                                                        }
                                                                    >
                                                                        <SettingsBackupRestore />
                                                                        version
                                                                    </Button>
                                                                    <Button
                                                                        size="small"
                                                                        aria-controls={
                                                                            open
                                                                                ? 'split-button-menu'
                                                                                : undefined
                                                                        }
                                                                        aria-expanded={
                                                                            open
                                                                                ? 'true'
                                                                                : undefined
                                                                        }
                                                                        aria-label="select merge strategy"
                                                                        aria-haspopup="menu"
                                                                        onClick={
                                                                            handleToggle
                                                                        }
                                                                    >
                                                                        {
                                                                            options[
                                                                                selectedVersionIndex
                                                                            ]
                                                                        }
                                                                    </Button>
                                                                </ButtonGroup>
                                                                */}
                                                                <Popper
                                                                    sx={{
                                                                        zIndex: 1,
                                                                    }}
                                                                    open={open}
                                                                    anchorEl={
                                                                        anchorRef.current
                                                                    }
                                                                    role={
                                                                        undefined
                                                                    }
                                                                    transition
                                                                    disablePortal
                                                                >
                                                                    {({
                                                                        TransitionProps,
                                                                        placement,
                                                                    }) => (
                                                                        <Grow
                                                                            {...TransitionProps}
                                                                            style={{
                                                                                transformOrigin:
                                                                                    placement ===
                                                                                    'bottom'
                                                                                        ? 'center top'
                                                                                        : 'center bottom',
                                                                            }}
                                                                        >
                                                                            <Paper>
                                                                                <ClickAwayListener
                                                                                    onClickAway={
                                                                                        handleClose
                                                                                    }
                                                                                >
                                                                                    <MenuList
                                                                                        id="split-button-menu"
                                                                                        autoFocusItem
                                                                                    >
                                                                                        {options.map(
                                                                                            (
                                                                                                option,
                                                                                                index
                                                                                            ) => (
                                                                                                <MenuItem
                                                                                                    key={
                                                                                                        option
                                                                                                    }
                                                                                                    disabled={
                                                                                                        index ===
                                                                                                        2
                                                                                                    }
                                                                                                    selected={
                                                                                                        index ===
                                                                                                        selectedVersionIndex
                                                                                                    }
                                                                                                    onClick={(
                                                                                                        event
                                                                                                    ) =>
                                                                                                        handleMenuItemClick(
                                                                                                            event,
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        option
                                                                                                    }
                                                                                                </MenuItem>
                                                                                            )
                                                                                        )}
                                                                                    </MenuList>
                                                                                </ClickAwayListener>
                                                                            </Paper>
                                                                        </Grow>
                                                                    )}
                                                                </Popper>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                </div>
                                            </Fade>
                                        ) : null}
                                    </Box>
                                </Container>
                            </>
                        )
                    )}
                </Box>
            </div>
        </Fade>
    );
};

export default ViewUploadPage;
