import { FaBoltLightning } from 'react-icons/fa6';
import { Moving } from '@mui/icons-material';

export const adminFilters = () => {
    return [
        {
            text: 'User Management',
            value: 'user-management',
            icon: <FaBoltLightning />,
        },
        {
            text: 'Reported Uploads',
            value: 'reported-uploads',
            icon: <Moving />,
        },
    ];
};
