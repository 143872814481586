import React from 'react';
import IconButton from '@mui/material/IconButton';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SiPatreon } from 'react-icons/si';
import { useCustomTheme } from '../contexts/ThemeContext';
import { FaBluesky } from 'react-icons/fa6';
import { alpha } from '@mui/material';
import { FaFacebook, FaPaypal, FaYoutube } from 'react-icons/fa';

export interface SocialItemLinksUserProfilePros {
    link: string;
    icon: JSX.Element;
}

export interface SocialLinksUserProfilePros {
    twitter: SocialItemLinksUserProfilePros;
    patreon: SocialItemLinksUserProfilePros;
    facebook: SocialItemLinksUserProfilePros;
    paypal: SocialItemLinksUserProfilePros;
    youtube: SocialItemLinksUserProfilePros;
    bluesky: SocialItemLinksUserProfilePros;
    discord: SocialItemLinksUserProfilePros;
}

export const SocialLinksUserProfile: SocialLinksUserProfilePros = {
    twitter: {
        link: 'https://x.com/',
        icon: <FaXTwitter />,
    },
    patreon: {
        link: 'https://www.patreon.com/',
        icon: <SiPatreon />,
    },
    youtube: {
        link: 'https://www.youtube.com/@',
        icon: <FaYoutube />,
    },
    facebook: {
        link: 'https://www.facebook.com/',
        icon: <FaFacebook />,
    },
    paypal: {
        link: 'https://paypal.me/',
        icon: <FaPaypal />,
    },
    bluesky: {
        link: 'https://bsky.app/profile/',
        icon: <FaBluesky />,
    },
    discord: {
        link: 'https://discord.gg/',
        icon: <FaDiscord />,
    },
};

interface SocialProps {
    twitter?: string;
    discord?: string;
    patreon?: string;
    blueSky?: string;
    youtube?: string;
    paypal?: string;
    facebook?: string;
}

const Socials: React.FC<SocialProps> = ({
    blueSky,
    twitter,
    patreon,
    discord,
    youtube,
    paypal,
    facebook,
}) => {
    const { theme, themeType } = useCustomTheme();

    const social = (icon: JSX.Element, link: string) => {
        return (
            <IconButton
                size="small"
                edge="start"
                sx={{
                    mr: 2,
                    ...(themeType === 'custom' && {
                        border: '1px solid',
                        backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        borderColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        '&:hover': {
                            backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        },
                    }),
                }}
                onClick={() => window.open(link, '_blank')}
            >
                {icon}
            </IconButton>
        );
    };

    return (
        <>
            {/* Conditionally render Twitter button */}
            {twitter &&
                social(
                    SocialLinksUserProfile.twitter.icon,
                    SocialLinksUserProfile.twitter.link + twitter
                )}

            {/* Conditionally render Discord button */}
            {discord &&
                social(
                    SocialLinksUserProfile.discord.icon,
                    SocialLinksUserProfile.discord.link + discord
                )}

            {/* Conditionally render Patreon button */}
            {patreon &&
                social(
                    SocialLinksUserProfile.patreon.icon,
                    SocialLinksUserProfile.patreon.link + patreon
                )}

            {/* Conditionally render Bluesky button */}
            {blueSky &&
                social(
                    SocialLinksUserProfile.bluesky.icon,
                    SocialLinksUserProfile.bluesky.link + blueSky
                )}

            {/* Conditionally render YouTube button */}
            {youtube &&
                social(
                    SocialLinksUserProfile.youtube.icon,
                    SocialLinksUserProfile.youtube.link + youtube
                )}

            {/* Conditionally render PayPal button */}
            {paypal &&
                social(
                    SocialLinksUserProfile.paypal.icon,
                    SocialLinksUserProfile.paypal.link + paypal
                )}

            {/* Conditionally render FaceBook button */}
            {facebook &&
                social(
                    SocialLinksUserProfile.facebook.icon,
                    SocialLinksUserProfile.facebook.link + facebook
                )}
        </>
    );
};

export default Socials;
