import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LabelInput from './LabelInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface Item {
    id: string;
    value: string | number;
    name: string;
}

interface SearchMultiSelectInputProps {
    id: string;
    label: string;
    value: string[]; // Ensure this is an array of strings
    items: Item[];
    handleChange: (field: string, value: any[]) => void; // Update this to expect an array
    handleBlur: (e: React.FocusEvent<any>) => void;
    hasSubmitted: boolean;
    errors?: string[] | string;
    touched?: boolean;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
    submitForm?: () => Promise<void>;
    sortByName?: boolean;
}

const SearchMultiSelectInput: React.FC<SearchMultiSelectInputProps> = ({
    caption,
    id,
    label,
    value,
    items,
    hasSubmitted,
    handleBlur,
    handleChange,
    enableLabel,
    isMobile,
    sortByName,
}) => {
    const { theme } = useCustomTheme();
    const labelId = `${id}-label`;

    const [savedValues, setSavedValues] = useState<Item[]>([]);

    const handleSelectChange = (newValue: Item[]) => {
        if (newValue.length > 0) {
            // Create a new array of IDs
            const newArray = newValue.map((item) => item.id);
            // Update the handleChange function with the new array
            handleChange(id, newArray);
        } else {
            handleChange(id, []);
        }

        setSavedValues(newValue);
    };

    // Sync savedValues when value or items change
    useEffect(() => {
        // Find the matching items based on value
        const matchedItems = value
            .map((val) => items.find((item) => item.id === val))
            .filter((item): item is Item => !!item); // Filter out undefined matches

        setSavedValues(matchedItems);
    }, [value, items]); // Run this whenever value or items change

    const isValueSelected = savedValues.length > 0; // Check if any value is selected

    return (
        <FormControl fullWidth margin="normal">
            <Box display="flex" alignItems="center">
                <Grid
                    container
                    alignItems={isMobile ? 'flex-start' : 'center'}
                    spacing={2}
                >
                    {!enableLabel ? (
                        <LabelInput
                            label={label}
                            enableLabel={enableLabel}
                            isMobile={isMobile}
                            caption={caption}
                        />
                    ) : null}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                        lg={enableLabel ? 12 : 9.8}
                        xl={enableLabel ? 12 : 10.25}
                    >
                        <Autocomplete
                            multiple
                            id={labelId}
                            options={items.sort((a, b) =>
                                sortByName ? a.name.localeCompare(b.name) : 0
                            )}
                            onBlur={(e) => {
                                handleBlur(e);
                            }}
                            disabled={hasSubmitted}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, selectedValues) =>
                                handleSelectChange(selectedValues)
                            } // Pass the value directly
                            value={savedValues}
                            filterSelectedOptions
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({
                                        index,
                                    });
                                    return (
                                        <Chip
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary.main,
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                            key={key}
                                            label={option.name}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            color: isValueSelected
                                                ? theme.palette.text.secondary
                                                : theme.palette.text.disabled,
                                            '&.Mui-focused': {
                                                color: theme.palette.primary
                                                    .main,
                                            },
                                        },
                                    }}
                                    {...params}
                                    label={enableLabel ? label : undefined}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </FormControl>
    );
};

export default SearchMultiSelectInput;
