// src/components/modal/ReuseDetailsModal.js

import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    GetUploadsParams,
    GetUploadsResponse,
    Upload,
    UploadOrderByFields,
    User,
} from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { SearchCardSizeOthers } from '../../helpers/UploadCardSizings';
import SearchResults from '../search/SearchResults';

interface ReuseDetailsModalProps {
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    isModalOpen: boolean;
    isMobile: boolean;
    user: User;
    handleReuseDetails?: (upload: Upload) => void;
    width?: string;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const ReuseDetailsModal: React.FC<ReuseDetailsModalProps> = ({
    isMobile,
    closeModal,
    isModalOpen,
    user,
    handleReuseDetails,
    width,
}) => {
    const modalContentRef = useRef<HTMLDivElement | null>(null);
    const apiWorker = useWorker(createApiWorker);
    const { theme } = useCustomTheme();
    const { token } = useApi();

    const [deleted, setDeleted] = useState<boolean>(false);

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const defaultCount = 12;
    const defaultPage = 1;
    const defaultOrderBy = UploadOrderByFields.CREATED_AT;
    const [currentPage, setCurrentPage] = useState<number>(defaultPage); // State for current page
    const [lastPage, setLastPage] = useState<number>(defaultPage);

    const uploadParamsDefault: GetUploadsParams = {
        count: defaultCount,
        page: defaultPage,
        orderBy: defaultOrderBy,
    };

    const handlePageChange = async (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setLoadingResults(true);
        setUploads([]);
        const newUploadParams: GetUploadsParams = {
            ...uploadParams,
            page: value,
        };
        const response = await apiWorker.getUploadsDirection(
            true,
            token,
            newUploadParams
        );
        handleUpdateData(response, newUploadParams);

        if (modalContentRef.current) {
            modalContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const [uploadParams, setUploadParams] =
        useState<GetUploadsParams>(uploadParamsDefault);

    const [uploads, setUploads] = useState<Upload[]>([]);

    useEffect(() => {
        const getUploads = async () => {
            if (token) {
                let updatedUploadParams = {
                    ...uploadParamsDefault,
                    userProfile: user.userProfile.uuid,
                };

                const response = await apiWorker.getUploadsDirection(
                    true,
                    token,
                    updatedUploadParams
                );

                handleUpdateData(response, updatedUploadParams);
            }
        };

        getUploads()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching uploads:', error);
            });
    }, [token]);

    const handleUpdateData = (
        response: GetUploadsResponse,
        params: GetUploadsParams
    ) => {
        setUploadParams(params);
        setUploads(response.data);
        setCurrentPage(response.meta.page.current);
        setLastPage(response.meta.page.last);
        setLoadingResults(false);
    };

    return (
        <CustomModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            isMobile={isMobile}
            isDeleted={deleted}
            width={width}
        >
            {/* Modal content passed directly as children */}
            <Typography variant="h3" color={theme.palette.text.secondary}>
                REUSE DETAILS
            </Typography>
            <Typography
                variant="body2"
                color={theme.palette.warning.main}
                sx={{ marginBottom: 3 }}
            >
                Select one of your previous uploads to reuse its details. You{' '}
                <b>will</b> be able to modify details before submitting.
                <br />
                <br />
                All information will be reused except for the image and download
                link.
            </Typography>
            <SearchResults
                loadingResults={loadingResults}
                isMobile={isMobile}
                uploads={uploads}
                previewCardSizes={SearchCardSizeOthers}
                lastPage={lastPage}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                user={user}
                count={uploadParams.count}
                handleReuseDetails={handleReuseDetails}
            />
        </CustomModal>
    );
};

export default ReuseDetailsModal;
