// Themes.ts
import { createTheme, Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const fadeTimeout = 500;

export const getOverallPadding = (
    isMobile: boolean,
    padding?: number
): number | undefined => {
    return isMobile ? 2 : padding !== undefined ? padding : undefined;
};

const typography = {
    fontFamily: ['Archivo', 'Arial', 'sans-serif'].join(','),
    h1: {
        fontSize: '64px', // Set font size to 12px
        fontWeight: 800, // Set font weight to Extra Bold
    },
    h2: {
        fontSize: '32px',
        fontWeight: 800,
    },
    h3: {
        fontSize: '24px',
        fontWeight: 800,
    },
    h4: {
        fontSize: '20px',
        fontWeight: 800,
    },
    h5: {
        fontSize: '16px',
        fontWeight: 800,
    },
    h6: {
        fontSize: '12px',
        fontWeight: 800,
    },
    body1: {
        fontSize: '16px',
        fontWeight: 400,
    },
    body2: {
        fontSize: '12px',
        fontWeight: 400,
    },
    subtitle2: {
        fontSize: '20px',
        fontWeight: 400,
    },
    caption: {
        fontSize: '12px',
        fontWeight: 800,
    },
};

export interface ThemeType {
    theme: Theme;
    themeType: 'default' | 'custom';
}

export const darkTheme: ThemeType = {
    theme: createTheme({
        typography: typography,
        palette: {
            mode: 'dark',
        },
    }),
    themeType: 'default',
};

export const lightTheme: ThemeType = {
    theme: createTheme({
        typography: typography,
        palette: {
            mode: 'light',
        },
    }),
    themeType: 'default',
};

export const discoveryRed: ThemeType = {
    theme: createTheme({
        mixins: {},
        typography: typography,
        palette: {
            mode: 'dark',
            primary: {
                main: '#FC244A',
            },
            secondary: {
                main: '#212735',
            },
            background: {
                paper: '#1B202C',
                default: '#212735',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#CED4EC',
                disabled: '#9FA4BA',
            },
        },
    }),
    themeType: 'custom',
};

const discoveryBlueBackgroundGradient =
    'linear-gradient(95deg, #15181F 0%, #171B34 59%)'; // Same colors as discoveryBlueBackgroundAlpha (
const discoveryBlueBackgroundAlpha = `linear-gradient(95deg, rgba(21, 24, 31, 0.7) 0%, rgba(23, 27, 52, 0.7) 59%)`;
export const discoveryWhiteText = '#FFFFFF';
export const discoveryWashedBlueText = '#CED4EC';
export const discoveryPreviewCardText = '#989cad';
const discoveryDisabledText = '#707485';
export const discoveryGreyBlue = '#293246';
const discoveryBlueColor = '#093FFF';
export const discoveryToolBar = '#11141B';

export const discoveryBlue = {
    theme: createTheme({
        mixins: {
            MuiDataGrid: {
                // Pinned columns sections
                pinnedBackground: '#ff0000',
                // Headers, and top & bottom fixed rows
                containerBackground: 'transparent',
            },
        },
        typography: typography,
        palette: {
            mode: 'dark',
            primary: {
                main: discoveryBlueColor,
                light: discoveryGreyBlue,
            },
            secondary: {
                main: '#15171F',
                light: '#20294C',
                dark: discoveryBlueBackgroundAlpha,
            },
            warning: {
                main: '#FCA324',
            },
            background: {
                default: '#171B34',
                paper: '#21253a',
            },
            text: {
                primary: discoveryWhiteText,
                secondary: discoveryWashedBlueText,
                disabled: discoveryDisabledText,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        height: '100%',
                        background: discoveryBlueBackgroundGradient,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        overscrollBehavior: 'none',
                        overflowX: 'hidden',
                    },
                    body: {
                        minHeight: '100%',
                        background: discoveryBlueBackgroundGradient,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        margin: 0,
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        overscrollBehavior: 'none',
                        overflowX: 'hidden',
                    },
                    '#root': {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: discoveryToolBar,
                        color: discoveryDisabledText,
                    },
                    arrow: {
                        color: discoveryToolBar, // Sets the arrow color to match the tooltip background
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: discoveryBlueColor, // Default checkbox color (light blue)
                        '&.Mui-checked': {
                            color: discoveryBlueColor, // Blue for checked state
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        backgroundImage: 'none',
                        backgroundColor: 'rgba(46, 55, 89, 0.15)',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: alpha(discoveryGreyBlue, 0.15),
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${alpha(discoveryGreyBlue, 0.15)}`,
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${discoveryWashedBlueText}`,
                            },
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `2px solid ${discoveryBlueColor}`,
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none', // Remove background image
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        backgroundImage: 'none', // Remove background image
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: discoveryWashedBlueText, // Input text color
                        '&::placeholder': {
                            color: discoveryDisabledText, // Placeholder text color
                            opacity: 1, // Full opacity for placeholder
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none', // Remove button drop shadow
                        '&:hover': {
                            boxShadow: 'none', // Remove hover drop shadow
                        },
                        '&.MuiButton-containedSuccess': {
                            color: 'white', // Change to your desired text color
                        },
                    },
                },
            },
        },
    }),
    themeType: 'custom',
};
