// src/components/modal/RejectModalUpload.js

import React from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    PostUploadApprovalProps,
    UploadApprovalStatusRequest,
} from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { REJECT_UPLOAD_FAILED } from '../../helpers/Messages';

interface RejectModalUploadProps {
    isMobile: boolean;
    handleUpdateStatus: (data: PostUploadApprovalProps) => Promise<boolean>;
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    setError: (error: string | undefined) => void; // Updated to match the state setter type
    isModalOpen: boolean;
    error?: string;
}

// Define validation schema using Yup
const validationSchemaUpdateUploadStatus = Yup.object({
    reason: Yup.string().required('Reason is required'),
});

const RejectModalUpload: React.FC<RejectModalUploadProps> = ({
    isMobile,
    closeModal,
    handleUpdateStatus,
    setError,
    isModalOpen,
    error,
}) => {
    const { theme } = useCustomTheme();

    return (
        <Formik
            initialValues={{
                reason: '',
            }}
            validationSchema={validationSchemaUpdateUploadStatus}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                const completed = await handleUpdateStatus({
                    status: UploadApprovalStatusRequest.REJECTED,
                    reason: values.reason,
                });

                if (completed) {
                    closeModal();
                } else {
                    setError(REJECT_UPLOAD_FAILED);
                }
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    onConfirm={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                >
                    {/* Modal content passed directly as children */}
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: 3,
                        }}
                        color={theme.palette.text.secondary}
                    >
                        REJECT
                    </Typography>
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    <TextInput
                        id={'reason'}
                        label={'REASON'}
                        value={values.reason}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.reason}
                        errors={errors.reason}
                        isMobile={isMobile}
                    />
                </CustomModal>
            )}
        </Formik>
    );
};

export default RejectModalUpload;
