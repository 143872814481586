import React, { useState } from 'react';
import { Fab, Typography, IconButton, Chip, Box } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    getUploadStatus,
    hasOpenReports,
    isUploadMember,
    isVersionMember,
    Upload,
    UploadStatus,
    User,
    Version,
} from '../../workers/ApiWorker';
import { Download, Favorite } from '@mui/icons-material';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

interface CoreInformationProps {
    upload: Upload;
    selectedVersion: Version;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const DownloadAndHearts: React.FC<CoreInformationProps> = ({
    upload,
    user,
    selectedVersion,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const { theme } = useCustomTheme();
    const [hearts, setHearts] = useState<number>(upload.stats.hearts);
    const [isHearted, setIsHearted] = useState<boolean>(
        isUploadMember(upload) ? upload.userHearted : false
    );

    const handleHeartClick = async () => {
        if (token && user) {
            try {
                await apiWorker.postUploadLike(upload.uuid, token);

                setHearts((prevHearts) =>
                    isHearted ? prevHearts - 1 : prevHearts + 1
                );
                setIsHearted(!isHearted);
            } catch (error) {
                console.warn('Could not update like', error);
            }
        }
    };

    const handleDownloadClick = async () => {
        if (token && user && isVersionMember(selectedVersion)) {
            try {
                const download = await apiWorker.getUploadVersionLink(
                    { uuid: upload.uuid, versionUuid: selectedVersion.uuid },
                    token
                );
                window.open(download.data.downloadLink, '_blank');
            } catch (error) {
                console.warn('Could not get download link', error);
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {
                    md: 'row',
                    lg: 'row',
                },
                justifyContent: {
                    md: 'flex-start',
                    lg: 'flex-end',
                },
                alignItems: 'center',
                gap: 2,
                marginTop: 2.5,
            }}
        >
            {upload ? (
                getUploadStatus(upload) === UploadStatus.PENDING ? (
                    <Chip
                        sx={{
                            marginTop: 1,
                            borderRadius: '8px',
                            backgroundColor: theme.palette.warning.main,
                        }}
                        size="small"
                        label={
                            <Typography
                                variant="caption"
                                color={theme.palette.text.primary}
                            >
                                {UploadStatus.PENDING}
                            </Typography>
                        }
                    />
                ) : getUploadStatus(upload) === UploadStatus.REJECTED ? (
                    <Chip
                        sx={{
                            marginTop: 1,
                            borderRadius: '8px',
                            backgroundColor: theme.palette.error.main,
                        }}
                        size="small"
                        label={
                            <Typography
                                variant="caption"
                                color={theme.palette.text.primary}
                            >
                                {UploadStatus.REJECTED}
                            </Typography>
                        }
                    />
                ) : (
                    <>
                        {hasOpenReports(upload, user) ? (
                            <Chip
                                sx={{
                                    marginTop: 1,
                                    borderRadius: '8px',
                                    backgroundColor: theme.palette.error.main,
                                }}
                                size="small"
                                label={
                                    <Typography
                                        variant="caption"
                                        color={theme.palette.text.primary}
                                    >
                                        {UploadStatus.REPORTED}
                                    </Typography>
                                }
                            />
                        ) : null}
                        {/* Box for Heart icon and number */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                            }}
                        >
                            <IconButton
                                disabled={!token}
                                onClick={handleHeartClick}
                                sx={{
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                <Favorite
                                    sx={{
                                        color: isHearted
                                            ? theme.palette.error.main
                                            : theme.palette.text.primary,
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="subtitle1"
                                sx={{ color: theme.palette.text.primary }}
                            >
                                {hearts}
                            </Typography>
                        </Box>

                        {token && user && isVersionMember(selectedVersion) && (
                            <Fab
                                color="primary"
                                aria-label="download"
                                onClick={handleDownloadClick}
                            >
                                <Download />
                            </Fab>
                        )}
                    </>
                )
            ) : null}
        </Box>
    );
};

export default DownloadAndHearts;
