import React from 'react';
import HomePage from '../pages/HomePage';
import GamesPage from '../pages/GamesPage';
import { MdGames, MdHome, MdSearch } from 'react-icons/md';
import ViewUploadPage from '../pages/ViewUploadPage';
import { AppRegistration, UploadFileRounded } from '@mui/icons-material';
import CreateUploadPage from '../pages/CreateUploadPage';
import LoginPage from '../pages/LoginPage';
import Login from '@mui/icons-material/Login';
import RegisterPage from '../pages/RegisterPage';
import SearchPage from '../pages/SearchPage';
import AdminPage from '../pages/AdminPage';
import SuspendedPage from '../pages/SuspendedPage';
import ViewUserPage from '../pages/ViewUserPage';
import { User } from '../workers/ApiWorker';
import FileUploadPage from '../pages/FileUploadPage';

export interface LinksProps {
    name: string;
    path: string;
    icon: JSX.Element;
    showIconBreadcrumb: boolean;
    page: JSX.Element;
    sideBar: boolean;
}

export const GamesLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Games',
        path: '/games',
        icon: <MdGames />,
        showIconBreadcrumb: true,
        page: <GamesPage isMobile={isMobile} padding={padding} user={user} />,
        sideBar: true,
    };
};

export const HomeLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Home',
        path: '/',
        icon: <MdHome />,
        showIconBreadcrumb: true,
        page: <HomePage isMobile={isMobile} padding={padding} user={user} />,
        sideBar: true,
    };
};

export const ViewUploadsLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'ViewUploadPage',
        path: ':username/:uuid',
        icon: <MdGames />,
        showIconBreadcrumb: false,
        page: (
            <ViewUploadPage isMobile={isMobile} padding={padding} user={user} />
        ),
        sideBar: false,
    };
};

export const ViewUserLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'ViewUserPage',
        path: ':username',
        icon: <MdGames />,
        showIconBreadcrumb: false,
        page: (
            <ViewUserPage
                isMobile={isMobile}
                padding={padding}
                user={user}
                width={width}
            />
        ),
        sideBar: false,
    };
};

export const CreateUploadsLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Upload',
        path: '/upload',
        icon: <UploadFileRounded />,
        showIconBreadcrumb: false,
        page: (
            <CreateUploadPage
                isMobile={isMobile}
                padding={padding}
                user={user}
            />
        ),
        sideBar: true,
    };
};

export const AdminLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Admin',
        path: '/admin',
        icon: <UploadFileRounded />,
        showIconBreadcrumb: false,
        page: <AdminPage isMobile={isMobile} padding={padding} user={user} />,
        sideBar: false,
    };
};

export const LoginLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Login',
        path: '/login',
        icon: <Login />,
        showIconBreadcrumb: false,
        page: <LoginPage />,
        sideBar: false,
    };
};

export const RegisterLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Register',
        path: '/register',
        icon: <AppRegistration />,
        showIconBreadcrumb: false,
        page: <RegisterPage />,
        sideBar: false,
    };
};

export const Searchink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Search',
        path: '/search',
        icon: <MdSearch />,
        showIconBreadcrumb: false,
        page: <SearchPage isMobile={isMobile} padding={padding} user={user} />,
        sideBar: false,
    };
};

export const SuspendedLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'Suspended',
        path: '/suspended',
        icon: <MdSearch />,
        showIconBreadcrumb: false,
        page: <SuspendedPage isMobile={isMobile} padding={padding} />,
        sideBar: false,
    };
};

export const FileUploadLink = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
) => {
    return {
        name: 'FileUpload',
        path: '/file-upload',
        icon: <MdGames />,
        showIconBreadcrumb: false,
        page: (
            <FileUploadPage isMobile={isMobile} padding={padding} user={user} />
        ),
        sideBar: false,
    };
};

export const links = (
    isMobile: boolean,
    padding?: number,
    user?: User,
    width?: number
): LinksProps[] => {
    return [
        HomeLink(isMobile, padding, user, width),
        GamesLink(isMobile, padding, user, width),
        ViewUserLink(isMobile, padding, user, width),
        ViewUploadsLink(isMobile, padding, user, width),
        CreateUploadsLink(isMobile, padding, user, width),
        LoginLink(isMobile, padding, user, width),
        RegisterLink(isMobile, padding, user, width),
        Searchink(isMobile, padding, user, width),
        AdminLink(isMobile, padding, user, width),
        SuspendedLink(isMobile, padding, user, width),
        FileUploadLink(isMobile, padding, user, width),
    ];
};
