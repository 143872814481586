// GamesPage.tsx
import React, { useEffect, useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { Fade, Grid } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Game, User } from '../workers/ApiWorker';
import Box from '@mui/material/Box';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import LoadingPreviewCard from '../components/universal/cards/LoadingPreviewCard';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface GamesPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const GamesPage: React.FC<GamesPageProps> = ({ isMobile, padding, user }) => {
    const apiWorker = useWorker(createApiWorker);
    const navigate = useNavigate();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const [games, setGames] = useState<Game[] | null>(null);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                setLoadingResults(true);
                const response = await apiWorker.getGames();

                // Sort games by name alphabetically
                const sortedGames = response.data.sort((a: Game, b: Game) =>
                    a.name.localeCompare(b.name)
                );

                setGames(sortedGames);
            } catch (error) {
                console.error('Error fetching games:', error);
                setGames(null);
            } finally {
                setLoadingResults(false);
            }
        };

        fetchGames();
    }, [apiWorker]);

    const handleClickValue = (searchValue: string) => {
        localStorage.removeItem('searchParams');
        const query = searchValue.trim();
        const searchId = new Date().getTime();
        if (query) {
            navigate(
                `/search?games=${encodeURIComponent(query)}&searchId=${searchId}`,
                { replace: true }
            );
        }
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden', padding: 2 }}>
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                    }}
                >
                    {loadingResults ? (
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            {Array.from({
                                length: 12,
                            }).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Fade
                                        in={loadingResults}
                                        timeout={fadeTimeout}
                                    >
                                        <div>
                                            {/* Show PreviewCard if uploads has data */}
                                            <LoadingPreviewCard
                                                invisible={false}
                                            />
                                        </div>
                                    </Fade>
                                </Grid>
                            ))}
                        </Grid>
                    ) : games && games.length > 0 ? (
                        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                            {games.map((game) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={game.uuid}
                                >
                                    <Fade
                                        in={!loadingResults}
                                        timeout={fadeTimeout / 2}
                                    >
                                        <Card
                                            sx={{
                                                height: 330,
                                                cursor: 'pointer',
                                                transition: 'transform 0.2s', // This will allow smooth transformation
                                                '&:hover': {
                                                    filter: 'brightness(1.1)', // Apply brightness on hover
                                                    transition:
                                                        'all 0.06s ease', // Apply transition for smooth effect
                                                },
                                            }}
                                            onClick={() =>
                                                handleClickValue(game.uuid)
                                            }
                                        >
                                            {game.image?.main ? (
                                                <>
                                                    <CardMedia
                                                        component="img"
                                                        alt={game.name}
                                                        image={game.image.main}
                                                        sx={{
                                                            height: '100%',
                                                            width: '100%',
                                                            objectFit:
                                                                'contain', // Ensures the image scales proportionally without cropping
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                        </Card>
                                    </Fade>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <NoResultsFound />
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default GamesPage;
