import React, { useEffect, useState } from 'react';
import { Button, Fade } from '@mui/material';
import { Upload, User } from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import Box from '@mui/material/Box';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import { useCustomTheme } from '../contexts/ThemeContext';
import {
    GridColDef,
    GridFilterModel,
    GridRowSelectionModel,
} from '@mui/x-data-grid';
import DataTable from '../components/universal/tables/DataTable';
import { Lens } from '@mui/icons-material';
import { convertSimpleWithTime } from '../helpers/Date';
import Chip from '@mui/material/Chip';
import { upperCaseFirst } from 'upper-case-first';
import Filters from '../components/filters/Filters';
import { adminFilters } from '../helpers/AdminFilters';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import UnsuspendUsersModal from '../components/modal/UnsuspendUsersModal';
import SuspendUsersModal from '../components/modal/SuspendUsersModal';
import { useNavigate } from 'react-router-dom';

interface AdminPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const AdminPage: React.FC<AdminPageProps> = ({ isMobile, padding, user }) => {
    const { theme } = useCustomTheme();
    const { token } = useApi();
    const apiWorker = useWorker(createApiWorker);
    const navigate = useNavigate();

    const [loadingReportedUploads, setLoadingReportedUploads] =
        useState<boolean>(true);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [selectedUsersRows, setSelectedUsersRows] =
        useState<GridRowSelectionModel>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]); // State for selected user
    const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false); // State for modal visibility
    const [isUnsuspendModalOpen, setIsUnsuspendModalOpen] = useState(false); // State for modal visibility

    const [selectedFilter, setSelectedFilter] = useState<string>(() => {
        const storedFilterModel = localStorage.getItem('adminFilter');

        // Check if storedFilterModel is null, return null if so
        if (storedFilterModel === null) {
            return adminFilters()[0].value;
        }

        return JSON.parse(storedFilterModel);
    });

    const handleSelectedFilter = (value: string) => {
        setSelectedUsers([]);
        setSelectedFilter(value);
        localStorage.setItem('adminFilter', JSON.stringify(value));
    };

    const showUnsuspendUsersModal = () => {
        setError(undefined);
        setIsUnsuspendModalOpen(true);
    };

    const showSuspendUsersModal = () => {
        setError(undefined);
        setIsSuspendModalOpen(true);
    };

    const closeSuspendUserModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setError(undefined);
        setIsSuspendModalOpen(false);
    };
    const closeUnsuspendUserModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setError(undefined);
        setIsUnsuspendModalOpen(false);
    };

    const [users, setUsers] = useState<User[]>([]);
    const [reportedUploads, setReportedUploads] = useState<Upload[]>([]);

    useEffect(() => {
        if (selectedUsersRows.length > 0) {
            const selectedUsersArray = users.filter((user) =>
                selectedUsersRows.includes(user.userProfile.uuid)
            );
            setSelectedUsers(selectedUsersArray);
        } else {
            setSelectedUsers([]);
        }
    }, [selectedUsersRows]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (token) {
            switch (selectedFilter) {
                case 'user-management':
                    setLoadingUsers(true);
                    setUsers([]);
                    const getUsers = async () => {
                        try {
                            const response = await apiWorker.getUsers({
                                count: 1000,
                            });
                            setUsers(response.data);
                        } catch (error) {
                            console.error(error);
                        }

                        setLoadingUsers(false);
                    };

                    getUsers().catch(console.error);
                    break;
                case 'reported-uploads':
                    setLoadingReportedUploads(true);
                    setReportedUploads([]);

                    const reportedUploads = async () => {
                        try {
                            const response = await apiWorker.getUploads(token, {
                                count: 1000,
                                status: 'reported',
                            });
                            setReportedUploads(response.data);
                        } catch (error) {
                            console.error(error);
                        }

                        setLoadingReportedUploads(false);
                    };

                    reportedUploads().catch(console.error);
                    break;
            }
        }
    }, [selectedFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    const columnsReportedUploads: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'author',
            headerName: 'Author',
            valueGetter: (value, row) => row.author.username,
        },
        {
            field: 'uuid',
            headerName: 'UUID',
        },
        {
            field: 'pendingReports',
            headerName: 'No. Reports',
        },
        {
            field: 'viewUpload',
            headerName: '',
            renderCell: (params) => {
                const username = params.row.author.username;
                const uuid = params.row.uuid;
                return (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            navigate(`/${username}/${uuid}`); // Adjust the path as necessary
                        }}
                    >
                        View Upload
                    </Button>
                );
            },
        },
    ];

    const columnsUser: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Username',
            valueGetter: (value, row) => row.userProfile.username,
        },
        {
            field: 'uuid',
            headerName: 'UUID',
        },
        {
            field: 'email',
            headerName: 'Email',
        },
        {
            field: 'accountType',
            headerName: 'Account Type',
            valueGetter: (value, row) => {
                const accountType = row.userProfile.accountType;
                return upperCaseFirst(accountType);
            },
        },
        {
            field: 'canBypass',
            headerName: 'Upload Approval Required?',
            type: 'boolean',
            valueGetter: (value, row) => !row.userProfile.canBypass,
            renderCell: (params) => {
                const canBypass = params.row.userProfile.canBypass;
                return (
                    <Lens
                        style={{
                            color: !canBypass
                                ? theme.palette.success.main
                                : theme.palette.error.main, // Green if canBypass is true, red if false
                            fontSize: '20px', // You can adjust the size of the icon
                        }}
                    />
                );
            },
        },
        {
            field: 'verifiedAt',
            headerName: 'Verified',
        },
        {
            field: 'ipAddress',
            headerName: 'IP Address',
        },
        {
            field: 'lastLoggedInAt',
            headerName: 'Last Active',
            type: 'dateTime',
            renderCell: (params) => {
                if (params.row.lastLoggedInAt !== null) {
                    return convertSimpleWithTime(params.row.lastLoggedInAt);
                }

                return convertSimpleWithTime(params.row.createdAt);
            },
            valueGetter: (value, row) => {
                if (row.lastLoggedInAt !== null) {
                    return new Date(row.lastLoggedInAt);
                }

                return new Date(row.createdAt);
            },
        },
        {
            field: 'accountStatus',
            headerName: 'Account Status',
            renderCell: (params) => {
                const accountStatus = params.row.userProfile.accountStatus;

                return (
                    <Chip
                        label={upperCaseFirst(accountStatus)}
                        sx={{
                            backgroundColor:
                                accountStatus === 'active'
                                    ? theme.palette.success.main
                                    : theme.palette.error.main,
                            color: theme.palette.text.primary,
                        }}
                    />
                );
            },
            valueGetter: (value, row) => row.userProfile.accountStatus,
        },
        {
            field: 'viewProfile',
            headerName: '',
            renderCell: (params) => {
                const username = params.row.userProfile.username;
                return (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            navigate(`/${username}`); // Adjust the path as necessary
                        }}
                    >
                        View Profile
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Fade in={true} timeout={fadeTimeout}>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        advancedContent={
                            <>
                                {/* Filters positioned at the bottom */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: isMobile ? 20 : 0, // Align the filters to the bottom of the container
                                        left: 0,
                                        right: 0, // Ensures it stretches to both ends
                                    }}
                                >
                                    <Filters
                                        isMobile={isMobile}
                                        selectedFilter={selectedFilter}
                                        filters={adminFilters}
                                        handleSelectedFilter={
                                            handleSelectedFilter
                                        }
                                        padding={padding}
                                        user={user}
                                    />
                                </Box>
                            </>
                        }
                    />

                    <Box
                        sx={{
                            paddingLeft: getOverallPadding(isMobile, padding),
                            paddingRight: getOverallPadding(isMobile, padding),
                            marginBottom: isMobile ? 10 : 3,
                        }}
                    >
                        {(() => {
                            switch (selectedFilter) {
                                case 'user-management':
                                    return !loadingUsers ? (
                                        users.length > 0 ? (
                                            <DataTable
                                                isMobile={isMobile}
                                                columns={columnsUser}
                                                rows={users}
                                                padding={padding}
                                                checkboxSelection={true}
                                                setSelectedRows={
                                                    setSelectedUsersRows
                                                }
                                                storedSettingsName="AdminUsers"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginTop: 2, // Optional for spacing above the button
                                                    }}
                                                    gap={2}
                                                >
                                                    <Button
                                                        disabled={
                                                            !selectedUsers.length
                                                        }
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            padding: '0.8rem',
                                                        }}
                                                        onClick={
                                                            showUnsuspendUsersModal
                                                        }
                                                    >
                                                        UNSUSPEND USERS
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            !selectedUsers.length
                                                        }
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{
                                                            padding: '0.8rem',
                                                        }}
                                                        onClick={
                                                            showSuspendUsersModal
                                                        }
                                                    >
                                                        SUSPEND USERS
                                                    </Button>
                                                </Box>
                                            </DataTable>
                                        ) : (
                                            <Fade
                                                in={true}
                                                timeout={fadeTimeout}
                                            >
                                                <div>
                                                    <NoResultsFound />
                                                </div>
                                            </Fade>
                                        )
                                    ) : null;
                                case 'reported-uploads':
                                    return !loadingReportedUploads ? (
                                        reportedUploads.length > 0 ? (
                                            <DataTable
                                                isMobile={isMobile}
                                                columns={columnsReportedUploads}
                                                rows={reportedUploads}
                                                padding={padding}
                                                storedSettingsName="AdminReportedUploads"
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginTop: 2, // Optional for spacing above the button
                                                    }}
                                                    gap={2}
                                                ></Box>
                                            </DataTable>
                                        ) : (
                                            <Fade
                                                in={true}
                                                timeout={fadeTimeout}
                                            >
                                                <div>
                                                    <NoResultsFound />
                                                </div>
                                            </Fade>
                                        )
                                    ) : null;
                                default:
                                    return (
                                        <Fade in={true} timeout={fadeTimeout}>
                                            <div>
                                                <NoResultsFound />
                                            </div>
                                        </Fade>
                                    );
                            }
                        })()}
                    </Box>
                </Box>
            </Fade>
            {/* Modal Component */}
            <SuspendUsersModal
                isMobile={isMobile}
                isModalOpen={isSuspendModalOpen}
                closeModal={closeSuspendUserModal}
                setError={setError}
                error={error}
                setUsers={setUsers}
                selectedUsers={selectedUsers}
                users={users}
            />
            <UnsuspendUsersModal
                isMobile={isMobile}
                isModalOpen={isUnsuspendModalOpen}
                closeModal={closeUnsuspendUserModal}
                setError={setError}
                error={error}
                setUsers={setUsers}
                selectedUsers={selectedUsers}
                users={users}
            />
        </>
    );
};

export default AdminPage;
