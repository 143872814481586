import React, { useState } from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    getUploadStatus,
    hasOpenReports,
    PostUploadApprovalProps,
    Report,
    Upload,
    UploadApprovalStatusRequest,
    UploadStatus,
    User,
    UserAccountTypes,
    Version,
} from '../../workers/ApiWorker';
import { convertSimple } from '../../helpers/Date';
import Box from '@mui/material/Box';
import UserAvatar from '../user/UserAvatar';
import { useApi } from '../../contexts/ApiContext';
import RejectUploadModal from '../modal/RejectUploadModal';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ReportUploadModal from '../modal/ReportUploadModal';
import ViewReportsModal from '../modal/ViewReportsModal';
import { UPDATE_UPLOAD_UPDATE_STATUS_FAILED } from '../../helpers/Messages';
import DeleteUploadModal from '../modal/DeleteUploadModal';

interface DetailedInformationProps {
    upload: Upload;
    setUpload: (upload: Upload) => void;
    version: Version;
    isMobile: boolean;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const DetailedInformation: React.FC<DetailedInformationProps> = ({
    upload,
    version,
    isMobile,
    user,
    setUpload,
}) => {
    const { token } = useApi();
    const { theme } = useCustomTheme();
    const apiWorker = useWorker(createApiWorker);

    const [success, setSuccess] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const [activeReportId, setActiveReportId] = useState<string | null>(null);

    const [reports, setReports] = useState<Report[]>([]);

    const [isDeclineUploadModalOpen, setIsDeclineUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility

    const [isReportUploadModalOpen, setIsReportUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility

    const [isViewReportsUploadModalOpen, setIsViewReportsUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility

    const [isDeleteUploadModalOpen, setIsDeleteUploadModalOpen] =
        useState<boolean>(false);

    const showViewReportsModal = () => {
        if (token) {
            const getReports = async () => {
                try {
                    const response = await apiWorker.getReportsByUpload(
                        upload.uuid,
                        token
                    );

                    if (response.data) {
                        setReports(response.data);
                    } else {
                        setReports([]);
                    }
                } catch (error) {
                    setReports([]);
                }
            };

            getReports()
                .then(() => {})
                .catch((error) => {
                    console.log('Error fetching reports for upload:', error);
                });

            setActiveReportId(null);
            setSuccess(undefined);
            setError(undefined);
            setIsViewReportsUploadModalOpen(true);
        }
    };

    const showDeleteUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(true);
    };

    const showReportModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(true);
    };

    const showDeclineUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(true);
    };

    const closeDeclineUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(false);
    };

    const closeReportUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setReports([]);
        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(false);
    };

    const closeDeleteUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(false);
    };

    const closeViewReportsModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setActiveReportId(null);
        setSuccess(undefined);
        setError(undefined);
        setIsViewReportsUploadModalOpen(false);
    };

    const handleUpdateStatus = async (
        data: PostUploadApprovalProps
    ): Promise<boolean> => {
        setError(undefined);
        setSuccess(undefined);
        let complete = false;
        if (upload && data && token) {
            try {
                const response = await apiWorker.postUploadApproval(
                    upload.uuid,
                    token,
                    data
                );
                setUpload(response.data);
                complete = true;
            } catch (error) {
                setError(UPDATE_UPLOAD_UPDATE_STATUS_FAILED);
            }
        }
        return complete;
    };

    return (
        <>
            <Grid
                container
                gap={5}
                textAlign="center"
                sx={{
                    justifyContent: isMobile ? 'center' : 'left',
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        date
                    </Typography>
                    <Typography variant="h5">
                        {convertSimple(version.createdAt)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        {upload.stats.views + 1}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        views
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.disabled }}
                    >
                        games
                    </Typography>
                    <Typography variant="h5">
                        {upload.games[0].name.toUpperCase()}
                    </Typography>
                    {upload.games.length > 1 && (
                        <Tooltip
                            title={
                                <Box sx={{ padding: 1 }}>
                                    {upload.games
                                        .slice(1)
                                        .map((game, index) => (
                                            <Typography
                                                key={index}
                                                variant="body2"
                                            >
                                                {game.name}
                                            </Typography>
                                        ))}
                                </Box>
                            }
                            placement="bottom"
                            arrow
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.text.disabled,
                                    cursor: 'pointer',
                                }}
                            >
                                + {upload.games.length - 1} more
                            </Typography>
                        </Tooltip>
                    )}
                </Grid>
                <Grid
                    item
                    xs={isMobile ? 12 : true}
                    marginTop={isMobile ? -2 : undefined}
                >
                    {token && user ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: isMobile ? 'center' : 'right',
                                gap: 1,
                            }}
                        >
                            {/* Conditional Button under Awaiting Approval */}
                            {user.userProfile.accountType !==
                                UserAccountTypes.MEMBER &&
                                getUploadStatus(upload) ===
                                    UploadStatus.PENDING &&
                                user.userProfile.uuid !== upload.author.uuid &&
                                handleUpdateStatus && (
                                    <>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            onClick={() =>
                                                handleUpdateStatus({
                                                    status: UploadApprovalStatusRequest.APPROVED,
                                                })
                                            }
                                        >
                                            APPROVE
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            onClick={() =>
                                                showDeclineUploadModal()
                                            }
                                        >
                                            REJECT
                                        </Button>
                                        {/* Modal Component */}
                                        <RejectUploadModal
                                            handleUpdateStatus={
                                                handleUpdateStatus
                                            }
                                            closeModal={closeDeclineUploadModal}
                                            setError={setError}
                                            error={error}
                                            isModalOpen={
                                                isDeclineUploadModalOpen
                                            }
                                            isMobile={isMobile}
                                        />
                                    </>
                                )}
                            {/*<Button variant="contained" type="submit">*/}
                            {/*    Subscribe*/}
                            {/*</Button>*/}

                            {user.userProfile.uuid !== upload.author.uuid &&
                            getUploadStatus(upload) === UploadStatus.APPROVED &&
                            user.userProfile.accountType ===
                                UserAccountTypes.MEMBER ? (
                                <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.error.main,
                                        }}
                                        onClick={() => showReportModal()}
                                    >
                                        Report
                                    </Button>
                                    <ReportUploadModal
                                        closeModal={closeReportUploadModal}
                                        setError={setError}
                                        error={error}
                                        isModalOpen={isReportUploadModalOpen}
                                        isMobile={isMobile}
                                        upload={upload}
                                        success={success}
                                        setSuccess={setSuccess}
                                    />
                                </>
                            ) : null}

                            {hasOpenReports(upload, user) ? (
                                <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.error.main,
                                        }}
                                        onClick={() => showViewReportsModal()}
                                    >
                                        View Reports
                                    </Button>
                                    <ViewReportsModal
                                        closeModal={closeViewReportsModal}
                                        setError={setError}
                                        error={error}
                                        isModalOpen={
                                            isViewReportsUploadModalOpen
                                        }
                                        isMobile={isMobile}
                                        uploadUuid={upload.uuid}
                                        reports={reports}
                                        success={success}
                                        setSuccess={setSuccess}
                                        setActiveReportId={setActiveReportId}
                                        activeReportId={activeReportId}
                                        setReports={setReports}
                                        setUpload={setUpload}
                                    />
                                </>
                            ) : null}

                            {getUploadStatus(upload) ===
                                UploadStatus.APPROVED &&
                            (user.userProfile.uuid === upload.author.uuid ||
                                user.userProfile.accountType !==
                                    UserAccountTypes.MEMBER) ? (
                                <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.error.main,
                                        }}
                                        onClick={() => showDeleteUploadModal()}
                                    >
                                        Delete Upload
                                    </Button>
                                    <DeleteUploadModal
                                        closeModal={closeDeleteUploadModal}
                                        setError={setError}
                                        error={error}
                                        isModalOpen={isDeleteUploadModalOpen}
                                        isMobile={isMobile}
                                        upload={upload}
                                    />
                                </>
                            ) : null}
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
            <Grid>
                <Grid item>
                    {upload.collaborators && upload.collaborators.length > 0 ? (
                        <>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: theme.palette.text.disabled,
                                    marginBottom: 1,
                                }}
                            >
                                collaborators
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    flexWrap: 'wrap', // Allow items to wrap to the next line
                                    overflow: 'hidden',
                                    justifyContent: isMobile
                                        ? 'center'
                                        : 'flex-start',
                                }}
                            >
                                {upload.collaborators.map(
                                    (collaborator, index) => (
                                        <UserAvatar
                                            key={index}
                                            backup={collaborator.username}
                                            dimensions={36}
                                            border={true}
                                            userProfile={collaborator}
                                            tooltip={true}
                                        />
                                    )
                                )}
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default DetailedInformation;
