// CustomModal.tsx
import React from 'react';
import { Modal, Fade, Box, Button, Typography, Link } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';

interface CustomModalProps {
    children?: React.ReactNode; // Content inside the modal (e.g. text, form, etc.)
    isOpen: boolean;
    onClose: () => void;
    cancelLabel?: string; // Label for the cancel button
    confirmLabel?: string; // Label for the confirm button
    onConfirm?: () => void; // Optional onConfirm handler for the "Confirm" button
    isSubmitting?: boolean;
    isValid?: boolean;
    isMobile?: boolean;
    isDeleted?: boolean;
    width?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
    children,
    isOpen,
    onClose,
    cancelLabel = 'Cancel', // Default cancel label
    confirmLabel = 'Confirm', // Default confirm label
    onConfirm, // Optional onConfirm handler
    isSubmitting,
    isValid,
    isMobile,
    isDeleted,
    width,
}) => {
    const { theme } = useCustomTheme();
    const navigate = useNavigate(); // Hook for navigation

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (!isSubmitting && !isDeleted) onClose();
            }}
        >
            <Fade in={isOpen}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '90%' : width ? width : '50%',
                        maxHeight: '98vh', // Restrict height to viewport
                        bgcolor: theme.palette.background.default,
                        borderRadius: 1,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        overflow: 'auto', // Enable scrolling if content overflows
                    }}
                >
                    {/* Modal Content */}
                    {isDeleted ? (
                        <Fade in={isOpen}>
                            <div>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        marginBottom: 3,
                                    }}
                                    color={theme.palette.text.secondary}
                                >
                                    SUCCESSFULLY DELETED
                                </Typography>
                                <Typography
                                    sx={{ marginBottom: 2 }}
                                    variant="body1"
                                >
                                    This no longer exists. Please click{' '}
                                    <Link
                                        component="button"
                                        onClick={() => navigate('/')}
                                        sx={{
                                            cursor: 'pointer',
                                            color: theme.palette.warning.main,
                                            textDecoration: 'none', // Ensure underline is shown
                                        }}
                                    >
                                        here
                                    </Link>{' '}
                                    to go back to the homepage.
                                </Typography>
                            </div>
                        </Fade>
                    ) : (
                        children
                    )}

                    {!isDeleted ? (
                        <>
                            {/* Render custom buttons directly, or if none, provide Cancel and Confirm buttons */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginTop: '15px',
                                }}
                            >
                                {onConfirm && ( // Only show the "Confirm" button if onConfirm is provided
                                    <Button
                                        disabled={isSubmitting || !isValid}
                                        onClick={() => {
                                            onConfirm();
                                        }}
                                        variant="contained"
                                        sx={{ mr: 2 }}
                                    >
                                        {confirmLabel}
                                    </Button>
                                )}
                                <Button
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                    color="inherit"
                                    variant="outlined"
                                >
                                    {cancelLabel}
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </Box>
            </Fade>
        </Modal>
    );
};

export default CustomModal;
