// ProgressAlert.tsx
import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface ProgressAlertInputProps {
    message: string;
}

const ProgressAlert: React.FC<ProgressAlertInputProps> = ({ message }) => {
    return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
            <Alert severity="warning" variant="outlined">
                {message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </Alert>
        </Box>
    );
};

export default ProgressAlert;
