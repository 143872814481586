// HomePage.tsx
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import Box from '@mui/material/Box';
import { Fade, Grid } from '@mui/material';
import {
    Game,
    GetUploadsParams,
    GetUploadsResponse,
    UploadOrderBy,
    UploadOrderByFields,
    Upload,
    User,
} from '../workers/ApiWorker';
import * as Yup from 'yup';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import TextInput from '../components/universal/inputs/TextInput';
import { Form, Formik } from 'formik';
import SelectInput from '../components/universal/inputs/SelectInput';
import MultiSelectInput from '../components/universal/inputs/MultiSelectInput';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import { SearchCardSizeOthers } from '../helpers/UploadCardSizings';
import SearchResults from '../components/search/SearchResults';

interface SearchPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

// Define validation schema using Yup
const validationSchema = Yup.object({
    name: Yup.string(),
    count: Yup.number(),
    page: Yup.number(),
    orderBy: Yup.string(),
    type: Yup.string(),
    game: Yup.string(),
});

const SearchPage: React.FC<SearchPageProps> = ({ isMobile, padding, user }) => {
    const { uploadTypes, token } = useApi();
    const apiWorker = useWorker(createApiWorker);

    const [searchParams] = useSearchParams();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);

    const allTypesOption = { id: 'all', value: 'all', name: 'All' };
    const defaultCount = 12;
    const defaultPage = 1;
    const defaultOrderBy = UploadOrderByFields.CREATED_AT;
    const defaultType = allTypesOption.value;

    const [games, setGames] = useState<Game[] | null>(null);

    const uploadParamsDefault: GetUploadsParams = {
        name: searchParams.get('query') ?? '',
        count: defaultCount,
        page: defaultPage,
        orderBy: defaultOrderBy,
        type: defaultType,
        gamesArray: searchParams.get('games')
            ? searchParams.get('games')!.split(',') // Split the string into an array
            : [], // Fallback to an empty array if null
    };

    const [uploadParams, setUploadParams] =
        useState<GetUploadsParams>(uploadParamsDefault);

    const [currentPage, setCurrentPage] = useState<number>(defaultPage); // State for current page
    const [lastPage, setLastPage] = useState<number>(defaultPage);

    const [uploads, setUploads] = useState<Upload[]>([]);

    const handleUpdateData = (
        response: GetUploadsResponse,
        params: GetUploadsParams
    ) => {
        localStorage.setItem('searchParams', JSON.stringify(params));
        setUploadParams(params);
        setUploads(response.data);
        setCurrentPage(response.meta.page.current);
        setLastPage(response.meta.page.last);
        setLoadingResults(false);
    };

    const handlePageChange = async (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setLoadingResults(true);
        setUploads([]);
        const newUploadParams: GetUploadsParams = {
            ...uploadParams,
            page: value,
        };
        const response = await apiWorker.getUploads(token, newUploadParams);
        handleUpdateData(response, newUploadParams);
    };

    // Effect to update query when searchParams changes
    useEffect(() => {
        const getGames = async () => {
            const response = await apiWorker.getGames();
            setGames(response.data);
        };
        getGames().catch(console.error);

        const cachedSearchParams = localStorage.getItem('searchParams');

        let updatedUploadParams = { ...uploadParamsDefault };

        if (cachedSearchParams) {
            updatedUploadParams = JSON.parse(cachedSearchParams);
        }

        const getUploads = async () => {
            setUploads([]);
            const response = await apiWorker.getUploads(
                token,
                updatedUploadParams
            );
            handleUpdateData(response, updatedUploadParams);
        };

        getUploads().catch(console.error);
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {}, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <PageTopSection
                    isMobile={isMobile}
                    padding={padding}
                    simpleContent={{
                        title: 'SEARCH',
                        info: (
                            <>
                                Explore uploads submitted to <b>Discovery</b>{' '}
                                using filters to find content that suits your
                                interests.
                            </>
                        ),
                    }}
                />
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                    }}
                >
                    <Grid container spacing={4}>
                        {/* Left section (1/3 of width) */}

                        <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                            <Box sx={{ position: 'sticky', top: 20 }}>
                                {/* Content for the left section */}
                                {uploadTypes && games ? (
                                    <Formik
                                        initialValues={uploadParams}
                                        validationSchema={validationSchema}
                                        enableReinitialize
                                        onSubmit={async (
                                            values,
                                            { resetForm }
                                        ) => {
                                            values.page = defaultPage;
                                            setUploads([]);
                                            setLoadingResults(true);
                                            const response =
                                                await apiWorker.getUploads(
                                                    token,
                                                    values
                                                );
                                            handleUpdateData(response, values);
                                        }}
                                    >
                                        {({
                                            errors,
                                            touched,
                                            values,
                                            handleChange,
                                            isSubmitting,
                                            handleBlur,
                                            isValid,
                                            setFieldValue,
                                            submitForm,
                                        }) => (
                                            <Form>
                                                <TextInput
                                                    id="name"
                                                    label="Search"
                                                    value={values.name}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    touched={touched.name}
                                                    errors={errors.name}
                                                    enableLabel
                                                    submitForm={submitForm}
                                                />

                                                <SelectInput
                                                    items={UploadOrderBy.map(
                                                        (item) => ({
                                                            id: item.field,
                                                            value: item.field,
                                                            name: item.name,
                                                        })
                                                    )}
                                                    id="orderBy"
                                                    label="ORDER"
                                                    value={
                                                        values.orderBy ||
                                                        UploadOrderByFields.CREATED_AT
                                                    }
                                                    errors={errors.orderBy}
                                                    touched={touched.orderBy}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    handleChange={setFieldValue}
                                                    isMobile={isMobile}
                                                    submitForm={submitForm}
                                                    pageSearch={true}
                                                />

                                                <SelectInput
                                                    items={[
                                                        {
                                                            id: 'all',
                                                            value: 'all',
                                                            name: 'All Categories',
                                                        },
                                                        ...uploadTypes.map(
                                                            (item) => ({
                                                                id: item.uuid,
                                                                value: item.uuid,
                                                                name: item.name,
                                                            })
                                                        ),
                                                    ]}
                                                    id="type"
                                                    label="CATEGORY"
                                                    value={
                                                        values.type ||
                                                        defaultType
                                                    }
                                                    errors={errors.type}
                                                    touched={touched.type}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    handleChange={setFieldValue}
                                                    isMobile={isMobile}
                                                    submitForm={submitForm}
                                                    pageSearch={true}
                                                />

                                                <MultiSelectInput
                                                    items={games.map(
                                                        (item) => ({
                                                            id: item.uuid,
                                                            value: item.uuid,
                                                            name: item.name,
                                                        })
                                                    )}
                                                    id="gamesArray"
                                                    label="GAMES"
                                                    value={
                                                        values.gamesArray || []
                                                    }
                                                    errors={errors.gamesArray}
                                                    touched={touched.gamesArray}
                                                    handleBlur={handleBlur}
                                                    hasSubmitted={isSubmitting}
                                                    handleChange={setFieldValue}
                                                    isMobile={isMobile}
                                                    submitForm={submitForm}
                                                    pageSearch={true}
                                                />
                                            </Form>
                                        )}
                                    </Formik>
                                ) : null}
                            </Box>
                        </Grid>

                        {/* Right section (2/3 of width) */}
                        <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                            <SearchResults
                                loadingResults={loadingResults}
                                isMobile={isMobile}
                                uploads={uploads}
                                previewCardSizes={SearchCardSizeOthers}
                                lastPage={lastPage}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                user={user}
                                count={uploadParams.count}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Fade>
    );
};

export default SearchPage;
