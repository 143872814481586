import React, { useEffect, useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { Button, Fade, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import {
    GetUploadsParams,
    GetUploadsResponse,
    Upload,
    UploadOrderByFields,
    User,
    UserProfile,
} from '../workers/ApiWorker';
import Card from '@mui/material/Card';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import { useLocation, useParams } from 'react-router-dom';
import { useApi } from '../contexts/ApiContext';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { upperCaseFirst } from 'upper-case-first';
import { PreviewCardSizeOthers } from '../helpers/UploadCardSizings';
import SearchResults from '../components/search/SearchResults';
import { useCustomTheme } from '../contexts/ThemeContext';
import axios from 'axios';
import { wait } from '@testing-library/user-event/dist/utils';
import { acceptedTypesArray, acceptedTypesString } from '../helpers/Images';
import Socials from '../helpers/Socials';
import UpdateUserModal from '../components/modal/UpdateUserModal';
import { FOLLOW_USER_FAILED } from '../helpers/Messages';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface ViewUserPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
    width?: number;
}

const ViewUserPage: React.FC<ViewUserPageProps> = ({
    isMobile,
    padding,
    user,
    width,
}) => {
    const apiWorker = useWorker(createApiWorker);

    const { theme } = useCustomTheme();
    const location = useLocation();
    const { username } = useParams<{ username: string }>(); // Extract uuid from URL
    const { userProfileUuid } = location.state || {};

    const { token } = useApi();

    const [pageLoaded, setPageLoaded] = useState<boolean>(true);
    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);
    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [loadedUser, setLoadedUser] = useState<UserProfile | null>(null);
    const [isLoadedUserMe, setIsLoadedUserMe] = useState<boolean>(false);
    const [followers, setFollowers] = useState<number>(0);
    const [following, setFollowing] = useState<number>(0);
    const [userFollowing, setUserFollowing] = useState<boolean>(false);
    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined);
    const [loadedUserImage, setLoadedUserImage] = useState<string | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<string | undefined>(undefined);

    const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false); // State for modal visibility
    const showUpdateUserModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsUpdateUserModalOpen(true);
    };

    const closeUpdateUserModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setError(undefined);
        setSuccess(undefined);
        setIsUpdateUserModalOpen(false);
    };

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const defaultCount = 12;
    const defaultPage = 1;
    const defaultOrderBy = UploadOrderByFields.CREATED_AT;
    const [currentPage, setCurrentPage] = useState<number>(defaultPage); // State for current page
    const [lastPage, setLastPage] = useState<number>(defaultPage);

    const uploadParamsDefault: GetUploadsParams = {
        count: defaultCount,
        page: defaultPage,
        orderBy: defaultOrderBy,
    };

    const [uploadParams, setUploadParams] =
        useState<GetUploadsParams>(uploadParamsDefault);

    const [uploads, setUploads] = useState<Upload[]>([]);

    // Trigger file input on card click
    const handleCardClick = () => {
        document.getElementById('imageUploadInput')?.click();
    };

    useEffect(() => {
        const postUploadImage = async () => {
            if (uploadImage && uploadImage && token) {
                if (loadedUserImage) {
                    URL.revokeObjectURL(loadedUserImage);
                }

                try {
                    const createImage = await apiWorker.postImage(
                        {
                            uuid: userProfileUuid,
                            type: 'main',
                            scope: 'users',
                            image: uploadImage,
                        },
                        token
                    );

                    if (loadedUserImage === null) {
                        await apiWorker.updateUserProfile(
                            {
                                imageMain: createImage.data.key,
                            },
                            token
                        );
                    }

                    window.location.reload();
                } catch (error) {
                    console.warn('error trying to upload image');
                }
            }
        };

        if (uploadImage && uploadImage && token) {
            postUploadImage()
                .then(() => {})
                .catch((error) => {
                    console.log('Error uploading image:', error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadImage]);

    // Function to handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Only allow one image
        if (file) {
            if (!acceptedTypesArray.includes(file.type)) {
                event.target.value = ''; // Reset the file input
                return;
            }
            // Clean up any previous object URL before creating a new one
            setUploadImage(file); // Store file temporarily in state
        }

        // Reset the file input so the same image can be uploaded again
        event.target.value = '';
    };

    const handleUpdateData = (
        response: GetUploadsResponse,
        params: GetUploadsParams
    ) => {
        setUploadParams(params);
        setUploads(response.data);
        setCurrentPage(response.meta.page.current);
        setLastPage(response.meta.page.last);
        setLoadingResults(false);
    };

    const handlePageChange = async (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setLoadingResults(true);
        setUploads([]);
        const newUploadParams: GetUploadsParams = {
            ...uploadParams,
            page: value,
        };
        const response = await apiWorker.getUploads(token, newUploadParams);
        handleUpdateData(response, newUploadParams);
    };

    useEffect(() => {
        const getUploads = async () => {
            await wait(250);
            if (loadedUser) {
                setUploads([]);

                let updatedUploadParams = {
                    ...uploadParamsDefault,
                    userProfile: loadedUser.uuid,
                };

                const response = await apiWorker.getUploadsDirection(
                    isLoadedUserMe,
                    token,
                    updatedUploadParams
                );

                handleUpdateData(response, updatedUploadParams);
            }
        };

        getUploads()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching uploads:', error);
            });
    }, [loadedUser, isLoadedUserMe]);

    useEffect(() => {
        const getUserForPage = async () => {
            setPageLoaded(false);
            setLoadedUser(null);
            setLoadingUser(true);
            setNoResultsFound(false);
            setIsLoadedUserMe(false);
            setFollowers(0);
            setFollowing(0);
            setUserFollowing(false);
            setUploadImage(undefined);
            setLoadedUserImage(null);
            setLoadingResults(true);
            setCurrentPage(defaultPage);
            setLastPage(defaultPage);
            setUploadParams(uploadParamsDefault);
            setUploads([]);

            if (token && user && user.userProfile.uuid === userProfileUuid) {
                await wait(10);
                setLoadedUser(user.userProfile);
                setFollowers(user.userProfile.follows.followers);
                setFollowing(user.userProfile.follows.following);
                setLoadedUserImage(user.userProfile.image.main);
                setUserFollowing(user.userProfile.userFollowing ?? false);
                setLoadingUser(false);
                setIsLoadedUserMe(true);
            } else {
                if (userProfileUuid) {
                    const response = await apiWorker.getUserProfiles(
                        {
                            uuid: userProfileUuid,
                        },
                        token
                    );

                    if (
                        response.meta.items.total === 1 &&
                        response.data[0].uuid === userProfileUuid
                    ) {
                        setLoadedUser(response.data[0]);
                        setFollowers(response.data[0].follows.followers);
                        setFollowing(response.data[0].follows.following);
                        setLoadedUserImage(response.data[0].image.main);
                        setUserFollowing(
                            response.data[0].userFollowing ?? false
                        );
                        setLoadingUser(false);
                    } else {
                        setLoadingUser(false);
                        setNoResultsFound(true);
                    }
                } else {
                    if (username) {
                        const response = await apiWorker.getUserProfiles(
                            {
                                username: username,
                            },
                            token
                        );

                        if (
                            response.meta.items.total === 1 &&
                            response.data[0].username === username
                        ) {
                            setLoadedUser(response.data[0]);
                            setLoadingUser(false);
                            setFollowers(response.data[0].follows.followers);
                            setFollowing(response.data[0].follows.following);
                            setLoadedUserImage(response.data[0].image.main);
                            setUserFollowing(
                                response.data[0].userFollowing ?? false
                            );
                        } else {
                            setLoadingUser(false);
                            setNoResultsFound(true);
                        }
                    } else {
                        setNoResultsFound(true);
                    }
                }
            }

            setPageLoaded(true);
        };

        getUserForPage()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching user:', error);
            });
    }, [username, userProfileUuid, token]);

    return pageLoaded ? (
        <Fade in={pageLoaded} timeout={fadeTimeout}>
            <div>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    {noResultsFound ? (
                        <NoResultsFound />
                    ) : (
                        !loadingUser &&
                        loadedUser && (
                            <>
                                <PageTopSection
                                    isMobile={isMobile}
                                    padding={padding}
                                    hideSeperator={true}
                                />
                                <Box
                                    sx={{
                                        paddingLeft: getOverallPadding(
                                            isMobile,
                                            padding
                                        ),
                                        paddingRight: getOverallPadding(
                                            isMobile,
                                            padding
                                        ),
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={isMobile ? 1 : 3}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'stretch',
                                        }}
                                    >
                                        {/* Left Side: Responsive Image */}
                                        <Grid
                                            item
                                            xs={
                                                width && width >= 700
                                                    ? 'auto'
                                                    : 12
                                            }
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                onClick={handleCardClick}
                                                sx={{
                                                    position: 'relative', // Allows absolutely positioned children to be placed within the Box
                                                    width: '212px',
                                                    height: '212px',
                                                    cursor:
                                                        user &&
                                                        user.uuid ===
                                                            loadedUser.uuid
                                                            ? 'pointer'
                                                            : 'default', // Fixing the ternary syntax
                                                }}
                                            >
                                                {/* Render the pencil icon if conditions are met */}
                                                {user &&
                                                    user.uuid ===
                                                        loadedUser.uuid && (
                                                        <Box
                                                            sx={{
                                                                position:
                                                                    'absolute', // Position the icon relative to the Box
                                                                top: 8, // Slight padding from the top
                                                                right: 8, // Slight padding from the left
                                                                zIndex: 1, // Ensure it stays on top of other content
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </Box>
                                                    )}

                                                {loadedUserImage ? (
                                                    <img
                                                        src={loadedUserImage}
                                                        alt="User Profile"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                ) : (
                                                    <h1
                                                        style={{
                                                            margin: 0,
                                                            backgroundColor:
                                                                theme.palette
                                                                    .text
                                                                    .disabled,
                                                            color: theme.palette
                                                                .text.primary,
                                                            fontSize: '4rem',
                                                            lineHeight: '212px',
                                                        }}
                                                    >
                                                        {loadedUser.username
                                                            .slice(0, 1)
                                                            .toUpperCase()}
                                                    </h1>
                                                )}

                                                {user &&
                                                    user.uuid ===
                                                        loadedUser.uuid && (
                                                        <input
                                                            id="imageUploadInput"
                                                            type="file"
                                                            accept={
                                                                acceptedTypesString
                                                            }
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            onChange={
                                                                handleFileChange
                                                            }
                                                        />
                                                    )}
                                            </Box>
                                        </Grid>

                                        {/* Right Side: Content */}
                                        <Grid
                                            item
                                            xs
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {/* Name and Role Row */}
                                            <Grid container spacing={0}>
                                                {/* Left Section: Username and Account Type */}
                                                <Grid
                                                    item
                                                    xs={
                                                        width && width >= 700
                                                            ? 6
                                                            : 12
                                                    }
                                                    sx={{
                                                        display:
                                                            width &&
                                                            width >= 700
                                                                ? 'flex'
                                                                : undefined,
                                                        flexDirection: 'column', // Stack content vertically
                                                        alignItems:
                                                            'flex-start', // Align content to the left
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h2"
                                                        color={
                                                            theme.palette.text
                                                                .secondary
                                                        }
                                                        gutterBottom
                                                    >
                                                        {loadedUser.username}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            theme.palette.text
                                                                .disabled
                                                        }
                                                        gutterBottom
                                                    >
                                                        {upperCaseFirst(
                                                            loadedUser.accountType
                                                        )}
                                                    </Typography>
                                                </Grid>

                                                {/* Right Section: Hello */}
                                                <Grid
                                                    item
                                                    xs={
                                                        width && width >= 700
                                                            ? 6
                                                            : 12
                                                    }
                                                    sx={{
                                                        display:
                                                            width &&
                                                            width >= 700
                                                                ? 'flex'
                                                                : undefined,
                                                        flexDirection: 'column', // Stack content vertically
                                                        alignItems: 'flex-end', // Align content to the left
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            theme.palette.text
                                                                .disabled
                                                        }
                                                        gutterBottom
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                color: theme
                                                                    .palette
                                                                    .text
                                                                    .secondary,
                                                            }}
                                                        >
                                                            {followers}
                                                        </span>{' '}
                                                        followers{' '}
                                                        <span
                                                            style={{
                                                                marginLeft: 10,
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                            ·
                                                        </span>{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                color: theme
                                                                    .palette
                                                                    .text
                                                                    .secondary,
                                                            }}
                                                        >
                                                            {following}
                                                        </span>{' '}
                                                        following
                                                    </Typography>
                                                    {user &&
                                                        (user.uuid !==
                                                        loadedUser.uuid ? (
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={async () => {
                                                                    if (
                                                                        token &&
                                                                        user &&
                                                                        loadedUser
                                                                    ) {
                                                                        try {
                                                                            await apiWorker.postUserProfileFollow(
                                                                                loadedUser.uuid,
                                                                                token
                                                                            );

                                                                            if (
                                                                                userFollowing
                                                                            ) {
                                                                                setFollowers(
                                                                                    followers -
                                                                                        1
                                                                                );
                                                                                setUserFollowing(
                                                                                    false
                                                                                );
                                                                            } else {
                                                                                setFollowers(
                                                                                    followers +
                                                                                        1
                                                                                );
                                                                                setUserFollowing(
                                                                                    true
                                                                                );
                                                                            }
                                                                        } catch (error) {
                                                                            console.warn(
                                                                                FOLLOW_USER_FAILED
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {userFollowing
                                                                    ? 'UNFOLLOW'
                                                                    : 'FOLLOW'}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={
                                                                    showUpdateUserModal
                                                                }
                                                            >
                                                                Edit Profile
                                                            </Button>
                                                        ))}
                                                </Grid>
                                            </Grid>

                                            {/* Description */}
                                            <Card
                                                sx={{
                                                    flexGrow: 1, // Make description take remaining space
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                    marginTop: 2,
                                                    textAlign: 'left', // Align text to the left
                                                    whiteSpace: 'pre-line', // Respect line breaks in the description
                                                    wordBreak: 'break-word', // Break long unbroken words
                                                }}
                                            >
                                                {loadedUser.description
                                                    ? loadedUser.description
                                                    : 'Hi! Welcome to my profile page.'}
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ marginTop: 2 }}>
                                        <Socials
                                            twitter={loadedUser.twitter}
                                            patreon={loadedUser.patreon}
                                            facebook={loadedUser.facebook}
                                            paypal={loadedUser.paypal}
                                            youtube={loadedUser.youtube}
                                        ></Socials>
                                    </Grid>
                                    <PageTopSection
                                        isMobile={isMobile}
                                        padding={padding}
                                        midPageSplit={true}
                                    />
                                    <Grid item xs={12}>
                                        <SearchResults
                                            loadingResults={loadingResults}
                                            isMobile={isMobile}
                                            uploads={uploads}
                                            previewCardSizes={
                                                PreviewCardSizeOthers
                                            }
                                            lastPage={lastPage}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                            user={user}
                                            count={uploadParams.count}
                                        />
                                    </Grid>
                                </Box>
                                {/* Modal Component */}
                                <UpdateUserModal
                                    isModalOpen={isUpdateUserModalOpen}
                                    isMobile={isMobile}
                                    loadedUser={loadedUser}
                                    setError={setError}
                                    error={error}
                                    setSuccess={setSuccess}
                                    success={success}
                                    setLoadedUser={setLoadedUser}
                                    closeModal={closeUpdateUserModal}
                                />
                            </>
                        )
                    )}
                </Box>
            </div>
        </Fade>
    ) : null;
};

export default ViewUserPage;
