// src/components/modal/DeleteUploadModal.js

import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { Upload } from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    CREATE_REPORT_FAILED,
    CREATE_REPORT_SUCCESS,
    DELETE_UPLOAD_FAILED,
    REJECT_UPLOAD_FAILED,
} from '../../helpers/Messages';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

interface DeleteUploadModalProps {
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    setError: (error: string | undefined) => void; // Updated to match the state setter type
    error?: string;
    isModalOpen: boolean;
    isMobile: boolean;
    upload: Upload;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

// Define validation schema using Yup
const validationSchema = Yup.object({
    reason: Yup.string().required('Reason is required'),
});

const DeleteUploadModal: React.FC<DeleteUploadModalProps> = ({
    isMobile,
    closeModal,
    setError,
    isModalOpen,
    error,
    upload,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { theme } = useCustomTheme();
    const { token } = useApi();

    const [deleted, setDeleted] = useState<boolean>(false);

    return (
        <Formik
            initialValues={{
                reason: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                setError(undefined);

                if (!token) {
                    return;
                }

                try {
                    await apiWorker.deleteUpload(upload.uuid, token, {
                        reason: values.reason,
                    });

                    setDeleted(true);
                } catch (error) {
                    setError(DELETE_UPLOAD_FAILED);
                }
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    onConfirm={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                    isDeleted={deleted}
                >
                    {/* Modal content passed directly as children */}
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: 3,
                        }}
                        color={theme.palette.text.secondary}
                    >
                        ARE YOU SURE?
                    </Typography>
                    <Typography sx={{ marginBottom: 2 }} variant="body1">
                        Are you sure you wish to delete the upload{' '}
                        <b>{upload.name}</b>?
                        <br />
                        <br />
                        Please note that once deleted, this upload will not
                        appear on the website.
                    </Typography>
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    <TextInput
                        id={'reason'}
                        label={'REASON'}
                        value={values.reason}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.reason}
                        errors={errors.reason}
                        isMobile={isMobile}
                    />
                </CustomModal>
            )}
        </Formik>
    );
};

export default DeleteUploadModal;
