import React from 'react';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { display } from '@mui/system';

interface TagProps {
    text: string;
    removable?: boolean;
    onDelete?: () => void;
    onClick?: () => void;
    searchable?: boolean;
    disabled?: boolean;
}

const Tag: React.FC<TagProps> = ({
    text,
    removable,
    onDelete,
    searchable,
    onClick,
    disabled,
}) => {
    const { theme } = useCustomTheme();

    return (
        <Chip
            sx={{
                color: theme.palette.text.secondary,
                borderRadius: '8px',
            }}
            variant="filled"
            color="primary"
            label={text.toUpperCase()}
            deleteIcon={removable ? <CloseIcon /> : undefined}
            onDelete={removable ? onDelete : undefined}
            onClick={searchable ? onClick : undefined}
            disabled={disabled}
        />
    );
};

export default Tag;
