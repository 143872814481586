// src/components/Footer.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import { discoveryToolBar } from '../../helpers/Themes';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { alpha } from '@mui/system';

const Footer = () => {
    const { themeType } = useCustomTheme();

    return (
        <Box
            sx={{
                bgcolor: discoveryToolBar,
                ...(themeType === 'custom' && {
                    boxShadow: 'none', // Remove shadow
                    backgroundImage: 'none', // Remove gradient or light effect
                }),
                borderTop: (theme) =>
                    `1px solid ${alpha(theme.palette.primary.light, 1)}`,
                p: 2,
                textAlign: 'center',
                marginTop: 'auto',
                zIndex: 10, // Higher than the background but not too high
                position: 'relative', // Ensure it’s positioned above fixed background
            }}
        >
            <Typography variant="body2" color="text.secondary">
                &copy; {new Date().getFullYear()} Your Company. All rights
                reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
